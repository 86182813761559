import React from 'react';

import {UserType} from './api/apiTypes';

import './App.css';
import {PageProhibitedIndicator} from './containers/page-prohibited-indicator';
import Home from './pages/home/home';
import ContactUs from './pages/contact/contact-us';
import ReserveRide from './pages/reserve-ride/reserve-ride';
import {LoginPage} from './pages/login/login-page';
import LoginDriver from './pages/login/login-driver';
import Admin from './pages/admin/adminpage';
import RideDetails from './pages/admin/ride-details';
import AboutUs from './pages/about/about-us';

export interface RouteProps {
    name: string;
    path: string;
    Component: JSX.Element;
    index?: boolean;
    IsProtected?: boolean;
    needsToBeInRoles?: UserType[];
    verticalNavbar?: boolean;
}

export const AllRoutes: RouteProps[] = [
    {
        name: 'Home',
        path: '/',
        Component: <Home />
    },
    {
        name: 'Home',
        path: '/home',
        Component: <Home />
    },
    {
        name: 'About Us',
        path: '/about-us',
        Component: <AboutUs />
    },
    {
        name: 'Contact Us',
        path: '/contact-us',
        Component: <ContactUs />
    },
    {
        name: 'Reserve Ride',
        path: '/reserve-ride',
        Component: <ReserveRide />
    },
    {
        name: 'Login',
        path: '/login',
        Component: <LoginPage />
    },
    {
        name: 'LoginDriver',
        path: '/logindriver',
        Component: <LoginDriver />
    },
    {
        name: 'Admin',
        path: '/admin',
        Component: <Admin />,
        needsToBeInRoles: [UserType.Driver],
    },
    {
        name: 'RideDetails',
        path: '/ride-details/:id', 
        Component: <RideDetails />,
    },
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        Component: <PageProhibitedIndicator />
    },
    {
        name: 'NotFound',
        path: '*',
        Component: <LoginPage />
    }
];
