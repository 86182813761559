import React from 'react';
import Typ from '@mui/material/Typography';
import {useTranslation} from "../providers/intlProvider";

const Typography = ({children, ...props}) => {
    const { t } = useTranslation();

    if(Array.isArray(children)) return <Typ {...props}>{children?.map(c => typeof c === 'string' ? t(c) : c)}</Typ>;
    else if (typeof children === 'string') return <Typ {...props}>{children && t(children)}</Typ>;
    else return <Typ {...props}>{children}</Typ>;
};

export default Typography;
