import { useCallback, useEffect, useRef, useState } from 'react';
import { getCurrentUser } from '../api/api';
import { CurrentUser } from '../api/apiTypes';
import { getLoggedInUserWatch } from '../auth/auth';

type UseUserOptions = {
    dependencies?: any[];
};

// Interval (in milliseconds) to wait before re-fetching the user data.
const REFETCH_INTERVAL = 1000;

/**
 * `useUser` Hook
 *
 * This custom hook is designed to manage user authentication and data fetching in a React component.
 * It utilizes Firebase authentication to determine the current user and fetches additional user data
 * from the server when a user is authenticated. Additionally, it implements a simple caching strategy
 * to reuse fetched data and minimize unnecessary API calls.
 *
 * - Returns an array with three elements:
 *   1. `user`: The user data fetched from the server, or `null` if no user is authenticated.
 *   2. `loading`: A boolean indicating whether the user data is currently being fetched.
 *   3. `error`: Any error that occurred during authentication or data fetching, or `null` if no error occurred.
 *
 * - Parameters:
 *   1. An optional `dependencies` array, which can be provided to refetch the user data when the specified dependencies change.
 *
 * @param {UseUserOptions} { dependencies } - Optional. Configuration options for the hook.
 *
 * @returns {[CurrentUser | null, boolean, unknown]} - An array containing the user data, loading state, and error, respectively.
 */
export const useUser = (
    { dependencies = [] }: UseUserOptions = { dependencies: [] }
): [CurrentUser | null, boolean, unknown] => {
    // State variables to manage the current user, loading state, and any potential error.
    const [user, setUser] = useState<CurrentUser | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    // A ref to cache the user data and the time it was fetched to avoid unnecessary API calls.
    const userCacheRef = useRef<{
        user: CurrentUser | null;
        fetchedAt: number | null;
    }>({ user: null, fetchedAt: null });

    // Callback to handle user data once it's fetched.
    // It updates the user state, caches the data, and updates the loading state.
    const handleUserLoaded = useCallback((loadedUser: CurrentUser) => {
        setUser(loadedUser);
        userCacheRef.current = { user: loadedUser, fetchedAt: Date.now() };
        setLoading(false);
    }, []);

    // Callback to handle errors. It updates the error state and the loading state.
    const handleError = useCallback((err: unknown) => {
        console.error(err); // Log the error for debugging purposes.
        setError(err); // Set the error state.
        setLoading(false); // Update loading state since loading is done, albeit with an error.
    }, []);

    // Effect hook to manage the fetching and caching of user data.
    useEffect(() => {
        // Check if there is cached user data and if it was fetched recently.
        // If it was, use the cached data and avoid the API call.
        const delta = Date.now() - (userCacheRef.current.fetchedAt ?? 0);
        if (
            userCacheRef.current.user &&
            userCacheRef.current.fetchedAt &&
            delta < REFETCH_INTERVAL
        ) {
            setUser(userCacheRef.current.user);
            setLoading(false);
            return;
        }

        // Subscribe to Firebase auth state changes.
        // If a user is logged in, fetch their data; if not, handle it as an error.
        const unsubscribe = getLoggedInUserWatch(
            (firebaseUser: any) => {
                if (firebaseUser) {
                    // Fetch the user data.
                    getCurrentUser(handleUserLoaded, handleError);
                } else {
                    // Handle a case where the user isn't logged in.
                    handleError(new Error('User is not logged in'));
                }
            },
            // Handle a Firebase auth error.
            (err: any) => handleError(new Error(`Firebase auth failed: ${err}`))
        );

        // Cleanup: unsubscribe from Firebase auth state changes when the component is unmounted.
        return () => {
            unsubscribe();
        };
    }, [handleUserLoaded, handleError, ...dependencies]); // Dependencies included here

    // Return the user, loading state, and error for use in components.
    return [user, loading, error];
};
