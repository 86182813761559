import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Grid, Chip, Stack, Button, IconButton, useMediaQuery, useTheme
} from '@mui/material';
import { Address, GeoLocation, ReservationDetails, ReservationStatus } from "../../api/apiTypes";
import { AccessTime, ArrowBack, DirectionsCar, LocationOn, Payment } from "@mui/icons-material";

export function ReservationCard({ reservation }: { reservation: ReservationDetails }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formatAddress = (address: Address, geolocation: GeoLocation) => {
        const formatted = `${address.street}, ${address.city}, ${address.state} `;

        const createMapLink = (address: string) => {
            const encodedAddress = encodeURIComponent(address);
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            return (
                <a href={mapUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 600 }}>
                    {address}
                </a>
            );
        };;

        return createMapLink(`${address.street}, ${address.city}, ${address.state}`)
    };

    const cardStyle = {
        maxWidth: '800px',
        margin: '20px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#ECECEC',
        padding: isMobile ? '0px' : '20px',
    };

    const iconStyle = {
        verticalAlign: 'middle',
        color: '#3c5c54',
        mr: 1
    };

    const formatDateTime = (date: string) => new Date(date).toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'America/Chicago'
    });


    const formatStatus = (status: ReservationStatus) => {
        // Implement a method to format the reservation status
        // Return a Chip component with appropriate label and style

        const color = {
            'REQUESTED': 'warning',
            'APPROVED': 'success',
            'CANCELLED': 'error',
            'DELIVERED': 'success'
        }[status] || 'info';
        return (
            <Chip color={color as any} label={status} /* Add styling based on the status */ />
        );
    };


    return (
        <Card sx={cardStyle}>
            <CardContent>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" href={`/admin`}><ArrowBack /></IconButton>
                    <Typography variant="h5" component="div" fontWeight="bold" paddingTop={0.5}>
                        Ride Reservation
                    </Typography>
                </Stack>

                <Divider style={{ margin: '10px 0' }} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontWeight="bold">
                            <AccessTime sx={iconStyle} />
                            <strong>Start: </strong><Typography sx={{textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 600, display: "inline"}} >{formatDateTime(reservation.start_time)}</Typography>
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                            <AccessTime sx={iconStyle} />
                            <strong>End: </strong><Typography sx={{textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 600, display: "inline"}} >{formatDateTime(reservation.end_time)}</Typography>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" fontWeight="bold">
                            <LocationOn sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>From: </strong> {formatAddress(reservation.start_address, reservation.start_geolocation)}
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                            <LocationOn sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>To: </strong> {formatAddress(reservation.end_address, reservation.end_geolocation)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" fontWeight="bold">
                            <DirectionsCar sx={{ verticalAlign: 'middle', color: '#3c5c54', mr: 1 }} />
                            <strong>Distance: </strong><Typography sx={{textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 600, display: "inline"}} >{reservation.distance_in_miles?.toFixed(1)} miles</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" fontWeight="bold">
                            <AccessTime sx={iconStyle} />
                            <strong>Duration: </strong><Typography sx={{textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 600, display: "inline"}} >{reservation.duration_in_minutes?.toFixed(1)} minutes</Typography> 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Status: </strong><Typography sx={{textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 600, display: "inline"}} >{formatStatus(reservation.status)}</Typography> 
                        </Typography>
                        <Typography variant="body1" fontWeight="medium" color="success" marginTop={1}>
                            <Payment sx={{ verticalAlign: 'middle', color: '#3c5c54', mr: 1 }} />
                            <strong>Estimated Price: </strong> <strong style={{ color: 'green' }}><Typography sx={{textDecoration: 'none', fontWeight: 600, display: "inline"}} >${reservation.price.toFixed(2)}</Typography></strong>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}