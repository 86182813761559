import { Box, CircularProgress, Typography } from '@mui/joy';
import React from 'react';

interface Props {
    children?: React.ReactNode;
    loadingText?: string;
}
export const PageLoadingIndicator = ({
                                         children,
                                         loadingText = 'Loading...'
                                     }: Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100vw!important',
                height: 'calc(100vh - 72px)!important'
            }}
        >
            {!!children && children}
            <Typography level={'h4'} textAlign={'center'}>
                {loadingText}
            </Typography>
            <CircularProgress variant='soft' />
        </Box>
    );
};
