import { useState, useEffect, useCallback, useContext } from 'react';
import { GoogleReCaptchaContext } from 'react-google-recaptcha-v3';
import {POST} from "../api/api";

export const useRecaptchaV3 = (action, dependencies = []) => {
    const [isSuspicious, setIsSuspicious] = useState(false);
    const { executeRecaptcha } = useContext(GoogleReCaptchaContext);

    const verifyRecaptcha = useCallback(async () => {
        if (!executeRecaptcha) {
            console.warn('reCAPTCHA has not been loaded');
            return;
        }

        try {
            const token = await executeRecaptcha(action);
            // Send this token to your server for verification
            const response = await POST('/v1/verify-recaptcha', { token });

            setIsSuspicious(((response as any)?.score || 0) < 0.5); // Assuming a threshold of 0.5 for suspicious activity
        } catch (error) {
            console.error('Error during reCAPTCHA verification:', error);
            setIsSuspicious(false); // Assume suspicious if there's an error
        }
    }, [executeRecaptcha, action]);

    useEffect(() => {
        verifyRecaptcha();
    }, [verifyRecaptcha, ...dependencies]);

    return isSuspicious;
};
