import { VisibilityOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import React from 'react';

interface Props {
    children?: React.ReactNode;
    text?: string;
}

const defaultText = 'You do not have permission to view this page.';
export const PageProhibitedIndicator = ({
                                            children,
                                            text = defaultText
                                        }: Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100vw!important',
                height: 'calc(100vh - 72px)!important'
            }}
        >
            <VisibilityOff sx={{ fontSize: '100px', opacity: '0.2' }} />
            <Typography level={'h1'} textAlign={'center'} color='primary'>
                Sorry,
            </Typography>
            <Typography level={'h4'} textAlign={'center'}>
                {text}
            </Typography>
            {!!children && children}
        </Box>
    );
};
