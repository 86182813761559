import React, { useState } from 'react';
import { Box, Container, IconButton, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosNewIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from "../../components/Typography";

type Testimonial = {
  name: string;
  testimonial: string;
  image: string;
};

// Mock data for testimonials. You can replace this with actual data.
const testimonials: Testimonial[] = [
    {
        name: "Daniel Markos",
        testimonial: "I've been using Kassa Ride for a few months now and it's been a game-changer for my daily commute. The app is easy to use and the drivers are always friendly and professional.",
        image: "https://via.placeholder.com/150"
    },
    {
        name: "Eyosias Samson",
        testimonial: "Kassa Ride has made my life so much easier! I no longer have to worry about finding parking or dealing with traffic. The drivers are always on time and the cars are clean and comfortable.",
        image: "https://via.placeholder.com/150"
    }
];

const TestimonialComponent: React.FC = () => {
    const [activeTestimonial, setActiveTestimonial] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handlePrev = () => {
      setActiveTestimonial((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
    };
  
    const handleNext = () => {
      setActiveTestimonial((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
    };
  
    return (
      <Container sx={{ py: isMobile ? 4 : 8, mb: 10}} maxWidth="md">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: isMobile ? 0 : 500, zIndex: 10 }}>
            <ArrowBackIosNewIcon fontSize="large" />
          </IconButton>
          
          <Box sx={{ maxWidth: 600, textAlign: 'center', position: 'relative', px: 8 }}>
            <Typography variant="h4" sx={{ fontSize: '37px', fontWeight: 'bold' }} gutterBottom>
              Testimonials
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={testimonials[activeTestimonial].image} alt="testimonial" style={{ width: 150, height: 150, borderRadius: '50%' }} />
              <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.primary" mt={2}>
                {testimonials[activeTestimonial].name}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: '14px', width: isMobile ? 'auto' : '500px' }} color="text.secondary" mt={2}>
                {testimonials[activeTestimonial].testimonial}
              </Typography>
            </Box>
          </Box>
  
          <IconButton onClick={handleNext} sx={{ position: 'absolute', right: isMobile ? 0 : 500 }}>
            <ArrowForwardIosNewIcon fontSize="large" />
          </IconButton>
        </Box>
      </Container>
    );
  };
  
  export default TestimonialComponent;
  