import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import { firebaseAuth } from '../firebase/firebase';

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = (resolve: any, reject: any) => {
    return signInWithPopup(firebaseAuth, googleProvider)
        .then(resolve)
        .catch(reject);
};

export const logInWithEmailAndPassword = async (
    email: string,
    password: string,
    resolve: any,
    reject?: any
) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password)
        .then(resolve)
        .catch(reject);
};

export const logInWithEmailAndPasswordAsync = (
    email: string,
    password: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        logInWithEmailAndPassword(email, password, resolve, reject);
    });
};

export const signUpWithEmailAndPassword = async (
    email: string,
    password: string,
    resolve: any,
    reject?: any
) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password)
        .then(resolve)
        .catch(reject);
};

export const signUpWithEmailAndPasswordAsync = (
    email: string,
    password: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        signUpWithEmailAndPassword(email, password, resolve, reject);
    });
};

export const getLoggedInUser = (resolve: any, reject?: any) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
        if (user) {
            console.log(user);
            resolve(user);
        }
        unsubscribe();
        resolve(user);
    }, reject);
    return unsubscribe;
};

export const getLoggedInUserAsync = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebaseAuth.onAuthStateChanged(
            (user) => {
                resolve(user);
                unsubscribe(); // Unsubscribe after getting the user
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const getLoggedInUserWatch = (
    resolve: any,
    reject?: any
): (() => void) => {
    let retries = 0;

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
        if (user) {
            resolve(user);
            retries = 0;
        } else if (retries < 3) {
            retries += 1;
        } else {
            reject('No user found.');
            retries = 0;
        }
    }, reject);

    return unsubscribe;
};

export const userIsLoggedIn = (resolve: any, reject: any) => {
    return getLoggedInUser(
        (user: any) => resolve(!!user),
        (err: any) => {
            console.log(err);
            reject(err);
        }
    );
};

export const userIsLoggedInAsync = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebaseAuth.onAuthStateChanged(
            (user) => {
                resolve(!!user);
                unsubscribe(); // Unsubscribe after getting the user
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const logout = (resolve: any, reject?: any) => {
    return signOut(firebaseAuth).then(resolve).catch(reject);
};
