export function getEnvVar(name: string) {
    if (process.env.STORYBOOK) {
        return (
            {
                BACKEND_URL: process.env.STORYBOOK_BACKEND_URL,
                GEOLOCATION_API_KEY: process.env.REACT_APP_GEOLOCATION_API_KEY
            }[name] || ''
        );
    }
    return (
        {
            BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
            GEOLOCATION_API_KEY: process.env.REACT_APP_GEOLOCATION_API_KEY
        }[name] || ''
    );
}

export const BackendURL = getEnvVar('BACKEND_URL');
export const GEOLOCATION_API_KEY = getEnvVar('GEOLOCATION_API_KEY');
