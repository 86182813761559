import React from 'react';

import Btn from '@mui/material/Button';
import {useTranslation} from "../providers/intlProvider";

const Button = ({children, ...props}) => {
    const {t} = useTranslation();

    if(Array.isArray(children)) return <Btn {...props}>{children?.map(c => typeof c === 'string' ? t(c) : c)}</Btn>;
    else if (typeof children === 'string') return <Btn {...props}>{children && t(children)}</Btn>;
    else return <Btn {...props}>{children}</Btn>;
};

export default Button;
