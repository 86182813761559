// dateUtils.ts

/**
 * Utility functions for handling date and time durations.
 *
 * This module provides a user-friendly way to represent durations of time.
 * Instead of manually calculating durations in milliseconds, you can use
 * these named exports to define durations in a more readable manner.
 */

// Basic units in milliseconds
const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY; // Approximate average
const YEAR = 365 * DAY; // Non-leap year

export const Duration = {
    milliseconds: (count: number) => count * MILLISECOND,
    seconds: (count: number) => count * SECOND,
    minutes: (count: number) => count * MINUTE,
    hours: (count: number) => count * HOUR,
    days: (count: number) => count * DAY,
    weeks: (count: number) => count * WEEK,
    months: (count: number) => count * MONTH, // Remember this is an approximation
    years: (count: number) => count * YEAR
};
