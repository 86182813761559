import {Button, Card, CardHeader} from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import {CardContent} from "@mui/joy";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {logout} from "../../auth/auth";
import {useSnackbar} from "../../hooks/useSnackbar";


export interface ProfileCardProps {
    name: string;
    email: string;
    phoneNumber: string;
    profilePicture: string;
    onLogout?: () => void;
}

export const UserProfileCard = ({name, email, phoneNumber, profilePicture, onLogout}: ProfileCardProps) => {
    const {showSnackbar} = useSnackbar()

    return (
        <Card sx={{maxWidth: {xs: 300, sm: 400}, marginY: 3, position: 'relative'}}>
            {onLogout && <Button size="small" sx={{position: 'absolute', top: 10, right: 10}} onClick={() => {
                logout(_ => {
                        showSnackbar("Logged out successfully", 'success')
                        onLogout()
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    },
                    err => {
                        showSnackbar("Error logging out", 'error')
                        console.error(err)
                    }
                )
            }}>
                Log out
            </Button>}
            <CardHeader
                avatar={
                    <Avatar
                        alt={name}
                        src={profilePicture}
                        sx={{width: {xs: 30, sm: 56}, height: {xs: 30, sm: 56}}}
                    />
                }
                title={name}
                subheader={email}
            />
            <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {phoneNumber && <Typography variant="body2" color="neutral" marginBottom={2} fontWeight="bold">
                        {phoneNumber}
                    </Typography>}
                </Box>
            </CardContent>
        </Card>
    );
};