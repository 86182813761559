import React from 'react';
import { CurrentUser } from '../api/apiTypes';

const UserContext = React.createContext<[CurrentUser | null, boolean, unknown]>(
    [null, false, null]
);

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
export default UserContext;
