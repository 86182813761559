import {Box} from '@mui/joy';
import 'firebase/auth';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import React, {useContext, useEffect} from 'react';
import Header from './pages/header/header';
import Footer from './pages/footer/footer';

import {
    BrowserRouter as Router,
    Route,
    Routes as Switch,
    useLocation,
    useNavigate
} from 'react-router-dom';
import {UserType} from './api/apiTypes';

import './App.css';
import {PageLoadingIndicator} from './containers/page-loading-indicator';
import {PageProhibitedIndicator} from './containers/page-prohibited-indicator';
import {useLayout} from './hooks/useLayout';
import {useUser} from './hooks/useUser';
import {LayoutProvider} from './providers/layoutProvider';
import UserContext, {UserProvider} from './providers/userProvider';
import {AllRoutes} from './routes';
import {LoadScript} from "@react-google-maps/api";
import {SnackbarProvider} from "./hooks/useSnackbar";
import {TranslationProvider} from "./providers/intlProvider";
import {GoogleReCaptchaContext, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {useRecaptchaV3} from "./hooks/useRecaptcha";

interface ProtectedRouteProps {
    children: JSX.Element;
    needsToBeInRoles?: UserType[];
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#3c5c54',
            main: '#3c5c54',
            dark: '#3c5c54',
            contrastText: '#fff',
        },
        secondary: {
            light: '#3c5c54',
            main: '#3c5c54',
            dark: '#3c5c54',
            contrastText: '#fff',
        },
    },
});

const libs = ['places'];

// ProtectedRoute is a wrapper around the Route component that checks if the user is logged in.
const Protected = (
    {
        children,
        needsToBeInRoles = []
    }: ProtectedRouteProps): JSX.Element => {
    const [user, loading, error] = useContext(UserContext);
    const [authorized, setAuthorized] = React.useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // If the hook has finished loading and no user is found or there's an error, navigate to the login page.
        if (!loading && (!user || error)) {
            navigate('/login');
        } else if (
            !loading &&
            user &&
            needsToBeInRoles.length > 0 &&
            !needsToBeInRoles.includes(user?.userType)
        ) {
            setAuthorized(false);
            navigate('/unauthorized');
        } else {
            setAuthorized(true);
        }
    }, [loading, user, error, navigate]);

    // If loading, show a loader or null. Once loading is done and a user is present, show the children.
    if (loading) return <PageLoadingIndicator/>;
    if (!authorized) return <PageProhibitedIndicator/>;
    return user && authorized ? children : <></>;
};

const hideNavbarOnRoutes = [];

const App = () => {
    const location = useLocation();
    const [user, loading, error] = useUser({
        dependencies: [location.pathname]
    });
    const [layoutInfo, setLayoutInfo] = useLayout();
    const { executeRecaptcha } = useContext(GoogleReCaptchaContext);

    const isSuspicious = useRecaptchaV3('main', [location.pathname]);
    (async () => {
        console.log("isSuspicious", executeRecaptcha && await executeRecaptcha);
    })()


    useEffect(() => {
        setLayoutInfo({
            ...layoutInfo,
            orientation:
                AllRoutes.find((r) => r.path === location.pathname)
                    ?.verticalNavbar || false
                    ? 'vertical'
                    : 'horizontal'
        });
    }, [location.pathname, setLayoutInfo]);

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyB5RWWh4S-0JZQgHqB8bAU_CjBYUTxHYrM"
            libraries={libs as any[]}
            onError={console.log}
            onLoad={() => console.log("Loading Google Maps script...")}
        >
            {isSuspicious ? <div>Sorry, we think you're a bot. Try reloading the page.</div> :
                <UserProvider value={[user, loading, error]}>
                <Header/>
                <Box
                    display='flex'
                    flexDirection={
                        layoutInfo?.orientation === 'vertical' ? 'row' : 'column'
                    }
                    top={0}
                    margin={
                        !hideNavbarOnRoutes.includes(location.pathname)
                            ? layoutInfo?.orientation === 'horizontal'
                                ? '5px 0 0 0'
                                : '0 0 0 82px'
                            : 'unset'
                    }
                    sx={{
                        width: '100vw',

                    }}
                >
                    <Switch>
                        {AllRoutes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                index={route.index}
                                element={
                                    route.IsProtected ? (
                                        <Protected
                                            needsToBeInRoles={
                                                route.needsToBeInRoles
                                            }
                                        >
                                            {route.Component}
                                        </Protected>
                                    ) : (
                                        route.Component
                                    )
                                }
                            />
                        ))}
                    </Switch>
                </Box>
                <Footer/>
            </UserProvider>}
        </LoadScript>
    );
};

const AppWrapper = () => {
    return (
        <TranslationProvider>
            <ThemeProvider theme={theme}>
                <GoogleReCaptchaProvider reCaptchaKey="6LfumDQpAAAAAIbsKHTBqV815E8bhBHkb6WNn95n">
                <Router>
                    <SnackbarProvider>
                        <LayoutProvider>
                            <App/>
                        </LayoutProvider>
                    </SnackbarProvider>
                </Router>
                </GoogleReCaptchaProvider>
            </ThemeProvider>
        </TranslationProvider>
    );
};
export default AppWrapper;
