import React from 'react';
import {
    Card, CardContent, Typography, Divider, Grid, Chip, Stack, IconButton,
    Box, useTheme, useMediaQuery
} from '@mui/material';
import { Address, GeoLocation, ReservationDetails, ReservationStatus } from "../../api/apiTypes";
import { AccessTime, ArrowBack, LocationOn, Payment } from "@mui/icons-material";

export function ConfirmationCard({ reservation }: { reservation: ReservationDetails }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const formatAddress = (address: Address, geolocation: GeoLocation) => {
        const addressString = `${address.street}, ${address.city}, ${address.state}`;
        const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`;
        return (
            <a href={mapUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                {addressString}
            </a>
        );
    };

    const formatDateTime = (date: string) => new Date(date).toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'America/Chicago'
    });

    const formatStatus = (status: ReservationStatus) => {
        const color = {
            'REQUESTED': 'warning',
            'APPROVED': 'success',
            'CANCELLED': 'error',
            'DELIVERED': 'success'
        }[status] || 'info';
        return <Chip color={color as any} label={status} variant="outlined" />;
    };

    return (
        <Card sx={{
            maxWidth: isMobile ? '100%' : '700px',
            margin: '20px auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            backgroundColor: isMobile ? '#ECECEC' : '#ECECEC'
        }}>
            <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton color="primary" href={`/reserve-ride`} size="large"><ArrowBack /></IconButton>
                    <Typography variant={isMobile ? 'h6' : 'h5'} component="div" fontWeight="bold">
                        Reservation Confirmed!
                    </Typography>
                </Stack>

                <Divider style={{ margin: '10px 0' }} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontWeight="medium">
                            <AccessTime sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>Start:</strong> {formatDateTime(reservation.start_time)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            <AccessTime sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>End:</strong> {formatDateTime(reservation.end_time)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" fontWeight="medium">
                            <LocationOn sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>From:</strong> {formatAddress(reservation.start_address, reservation.start_geolocation)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            <LocationOn sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>To:</strong> {formatAddress(reservation.end_address, reservation.end_geolocation)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p><strong>Drivers Car:</strong> Red/White 2023 Tesla Model Y. License plate <code>KEP919</code> <i>or</i> <code>EYT670</code> </p>
                        <Typography variant="body1" fontWeight="medium" color="success" marginTop={1}>
                            <Payment sx={{ verticalAlign: 'middle', color: theme.palette.primary.main, mr: 1 }} />
                            <strong>Estimated Price:</strong> <Box component="span" sx={{ color: theme.palette.success.main }}>${reservation?.['payment_rate']?.['PayAmount']?.toFixed(2)}</Box>
                            <br/>
                            <p>We accept all credit cards as well as cash payments. Payment will be made when your driver comes to pick you up.</p>
                            <img height="30px" src="https://firebasestorage.googleapis.com/v0/b/kassa-ride.appspot.com/o/credit-cards-color-logo%20(1)%20(1).png?alt=media" alt="PayPal Credit Card Logos" />
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="body1" fontWeight="medium" marginTop={1}>
                    You will receive an email from us soon. Thank you for choosing KassaRide!
                </Typography>
            </CardContent>
        </Card>
    );
}
