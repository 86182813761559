import React, {createContext, useState, useContext, useEffect, useRef} from 'react';
import translations from '../intl/intl';

export type TranslationFunction = (key: string) => string;

export type SetLanguageFunction = (language: string) => void;

export interface TranslationContextType {
    t: TranslationFunction;
    setLanguage: SetLanguageFunction;
    currentLanguage: string;
}

export const TranslationContext = createContext<TranslationContextType | null>(null);

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');
    const missingTranslationsRef = useRef({});

    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang) {
            setLanguage(lang);
        } else {
            localStorage.setItem('lang', language);
        }
    }, []);

    function handleSetLanguage(lang) {
        setLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    const t: TranslationFunction = (key) => {
        if (language === 'en') {
            return key;
        }

        const currentTranslations = translations[language];
        if (currentTranslations && key in currentTranslations) {
            return currentTranslations[key] || key;
        } else {
            if (!(key in missingTranslationsRef.current)) {
                missingTranslationsRef.current[key] = '';
                console.log('Missing translation:', key);
                // Optionally, log the entire current state of missing translations
                console.log('Current missing translations:', missingTranslationsRef.current);
            }
            return key; // Fallback to key
        }
    };

    const value: TranslationContextType = {
        t,
        setLanguage: handleSetLanguage,
        currentLanguage: language,
    };

    return (
        <TranslationContext.Provider value={value}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = (): TranslationContextType => {
    const context = useContext(TranslationContext);
    if (!context) {
        throw new Error('useTranslation must be used within a TranslationProvider');
    }
    return context;
};
