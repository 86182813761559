export class AppError {
    public message: string;
    public statusCode: number;
    public errorCode?: string;

    constructor(message: string, statusCode: number, errorCode?: string) {
        this.message = message;
        this.statusCode = statusCode;
        this.errorCode = errorCode;
    }

    // Checkers
    public isClientError(): boolean {
        return this.statusCode >= 400 && this.statusCode < 500;
    }

    public isServerError(): boolean {
        return this.statusCode >= 500 && this.statusCode < 600;
    }

    public isNotFoundError(): boolean {
        return this.statusCode === 404;
    }

    public isValidationError(): boolean {
        return this.statusCode === 400;
    }

    public isUnauthorizedError(): boolean {
        return this.statusCode === 401;
    }

    // ... add more checks as needed
}
