import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { format, addDays, startOfWeek, startOfMonth, endOfMonth, endOfWeek, isSameDay, addMonths, subMonths, isBefore } from 'date-fns';
import ReservedRidesComponent from './reserved-rides';
import './admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useData } from "../../hooks/useData";
import { getReservationsForDriverBetweenDates } from "../../api/api";
import { func } from 'prop-types';
import { get, set } from 'lodash';
import { Box, CircularProgress } from '@mui/material';

const Admin = () => {
    // Mock values for demonstration
    const [reservedDates, setReservedDates] = useState([]);
    const [reservedRides, setReservedRides] = useState([]);
    const [displayedRides, setDisplayedRides] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const calculateCalendarDates = useCallback(() => {
        const start = startOfWeek(startOfMonth(currentMonth));
        let end = endOfWeek(endOfMonth(currentMonth));

        // Check if the end of the month is a Sunday
        if (end.getDay() === 6) {
            end = addDays(end, 1); // Add one day to include the last day if it's a Sunday
        }
        return [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')];
    }, [currentMonth]);

    useEffect(() => {
        setStartAndEndDates(calculateCalendarDates());
    }, [calculateCalendarDates, currentMonth]);
    const [startAndEndDates, setStartAndEndDates] = useState<string[]>(calculateCalendarDates());


    const [rides, loading, err] = useData({
        apiFunction: getReservationsForDriverBetweenDates,
        body: {
            startDate: startAndEndDates[0],
            endDate: startAndEndDates[1],
        },
        dependencies: [startAndEndDates],
    });

    // Function to process rides data
    const processRidesData = (ridesData) => {
        if (!ridesData) return { processedRides: [], dates: [] };
        const processedRides = ridesData.map(ride => ({
            id: ride.ID,
            name: ride.rider.first_name ? `${ride.rider.first_name} ${ride.rider.last_name}` : 'Unknown Driver',
            datetime: ride.start_time,
            status: ride.status,
            payrate: parseFloat(ride.payment_rate.PayAmount).toFixed(2),
            // Add other necessary fields from the ride data
        }));

        const dates = ridesData.map(ride => new Date(ride.start_time));

        return { processedRides, dates };
    };

    useEffect(() => {
        if (rides) {
            const { processedRides, dates } = processRidesData(rides);
            setReservedRides(processedRides);
            setReservedDates(dates);

            // Automatically select today's rides
            const today = new Date();
            const todayRides = processedRides.filter(ride => {
                const rideDate = new Date(ride.datetime);
                return rideDate.getFullYear() === today.getFullYear() &&
                    rideDate.getMonth() === today.getMonth() &&
                    rideDate.getDate() === today.getDate();
            });

            setDisplayedRides(todayRides);
        }
    }, [rides]);

    if (loading) {
        return (
            <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                minHeight="80vh"
            >
                <CircularProgress/>
            </Box>
        );
    }

    if (err) {
        console.error('Error:', err);
    }

    if (!rides) {
        return <div>No rides found</div>;
    }

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header-admin row flex-middle">
                <div className="col-admin col-start">
                    <FontAwesomeIcon onClick={prevMonth} icon={faChevronLeft} color='#617770' />
                </div>
                <div className="col-admin col-center">
                    <span>{format(currentMonth, dateFormat)}</span>
                </div>
                <div className="col-admin col-end" onClick={nextMonth}>
                    <FontAwesomeIcon onClick={nextMonth} icon={faChevronRight} color='#617770' />
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        const startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col-admin col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div className="days-admin row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const today = new Date();
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";

        // Function to count reserved rides for a given day
        const countRidesOnDate = (date) => {
            return reservedDates.filter(d => isSameDay(d, date)).length;
        };

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                const rideCount = countRidesOnDate(cloneDay);
                const dots = [];

                // Create a dot for each ride
                for (let j = 0; j < rideCount; j++) {
                    dots.push(<div key={j} className="dot-admin"></div>);
                }

                const isReserved = reservedDates.some(reservedDate => isSameDay(reservedDate, cloneDay));
                const isDisabled = isBefore(cloneDay, today) && !isSameDay(cloneDay, today);

                days.push(
                    <div
                        className={`col-admin cell-admin ${isSameDay(day, selectedDate) ? "selected" : ""} ${isReserved ? "reserved" : ""} ${isDisabled ? "disabled" : ""}`}
                        key={day.toString()}
                        onClick={!isDisabled ? () => onDateClick(cloneDay) : undefined}
                    >
                        <span className="number-admin">{formattedDate}</span>
                        <div className="dots-container">{dots}</div> {/* Render dots here */}
                    </div>
                );

                day = addDays(day, 1);
            }
            rows.push(
                <div className="row-admin" key={day.toString()}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body-admin">{rows}</div>;
    };


    const onDateClick = (day: Date) => {
        setSelectedDate(day);

        const filteredRides = reservedRides.filter(ride => {
            const rideDate = new Date(ride.datetime);

            return rideDate.getFullYear() === day.getFullYear() &&
                rideDate.getMonth() === day.getMonth() &&
                rideDate.getDate() === day.getDate();
        });

        console.log('Filtered Rides:', filteredRides);
        setDisplayedRides(filteredRides);
    };



    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="admin-calendar-container">
            <div className="calendar-admin">
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
            <ReservedRidesComponent reservedRides={displayedRides} selectedDate={selectedDate} />
        </div>
    );
};

export default Admin;
