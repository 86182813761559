// Importing React and necessary types
import React from 'react';
import './about-us.css';
import File from '../../assets/aboutdoc.jpg';
import File2 from '../../assets/aboutdoc2.jpg';
import File3 from '../../assets/aboutdoc3.jpg';
import File4 from '../../assets/aboutdoc4.jpg';
import Kassa from '../../assets/kassa.png';
import {useTranslation} from "../../providers/intlProvider";
import Typography from "../../components/Typography";

type DriverInfo = {
    name: string;
    bio: string;
    img: string;
};


const AboutUsPage: React.FC = () => {
    const {t} = useTranslation();
    const driver: DriverInfo = {
        name: t('Kassahun Zeleke'),
        bio: t('Kassahun has been a professional driver for over 10 years, known for his punctuality and friendly service. He loves exploring new routes and ensuring the safest and most comfortable journey for his passengers.'),
        img: Kassa
    };

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalImageSrc, setModalImageSrc] = React.useState("");

    return (
        <div className="about-us-container">
            <h1>{t("About Us")}</h1>
            <Typography variant="body-sm">We are a company that specializes in providing personalized and reliable transportation services.</Typography>
            <div className="driver-section">
                <div className="driver-photo">
                   <img src={driver.img} alt='kassa' />
                </div>
                <div className="driver-info">
                    <h2>{driver.name}</h2>
                    <p>{driver.bio}</p>
                </div>
            </div>
            <h2>{t("Transparency")}</h2>
            <p>{t("Our Commitment to Safety and Transparency")}</p>
            <div className="pdf-download-container">
                <a href="https://drive.google.com/file/d/13N8PFz4CdY3HOP2rULAZdb5PdSpw10IZ/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <button className="pdf-download-button">{t("Download PDF")}</button>
                </a>
            </div>
            <div className="image-container">
                <img src={File} onClick={() => {
                    setIsModalOpen(true);
                    setModalImageSrc(File);
                }} />
                 <img src={File2} onClick={() => {
                    setIsModalOpen(true);
                    setModalImageSrc(File2);
                }} />
                <img src={File3} onClick={() => {
                    setIsModalOpen(true);
                    setModalImageSrc(File3);
                }} />
                <img src={File4} onClick={() => {
                    setIsModalOpen(true);
                    setModalImageSrc(File4);
                }} />
            </div>
            {
                isModalOpen && (
                    <div className="modal">
                        <span className="close" onClick={() => setIsModalOpen(false)}>X</span>
                        <img className="modal-content" src={modalImageSrc} />
                    </div>
                )
            }
        </div>
    );
};

export default AboutUsPage;
