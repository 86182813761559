import { useCallback, useState, useEffect, useContext } from 'react';
import {
    Box,
    Container,
    Paper,
    Grid,
    TextField,
    Autocomplete,
    Stack,
    CircularProgress,
    Snackbar, Alert, AlertColor
} from '@mui/material';
import Calendar from './date-time-picker';
import './styles.css'
import {logout, signInWithGoogle} from "../../auth/auth";
import { UserProfileCard } from "./user-profile";
import MapPickerDialog from "./location-picker-dialog";
import {Address, ContactRequest, CreateReservationRequest, Reservation, ReservationDetails} from "../../api/apiTypes";
import AddressCard from "./addressCard";
import {
    getAvailabilityBetweenDates,
    createRiderAccount,
    sendContactRequest,
    createRiderReservation
} from '../../api/api';
import { useData } from '../../hooks/useData';
import { format, startOfWeek, startOfMonth, endOfWeek, endOfMonth, addMonths, addDays } from 'date-fns';

import { LoadScript } from '@react-google-maps/api';
import UserContext from "../../providers/userProvider";
import { useSnackbar } from "../../hooks/useSnackbar";
import {ConfirmationCard} from "./confirmation-detail-card";

import Typography from "../../components/Typography";
import Button from "../../components/Button";
import {useTranslation} from "../../providers/intlProvider";

export function toCSTISOString(date) {
    // CST is UTC-6
    const offset = 6 * 60; // in minutes
    const localTime = new Date(date.getTime() - offset * 60000);

    // Format to ISO string and replace Z with the -06:00 offset
    return localTime.toISOString().replace('Z', '-06:00');
}

const ReserveRidePage: React.FC = () => {
    const [step, setStep] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState<Date>(null);
    const [user, setUser] = useState<{ email: string, displayName: string, photoURL: string, phoneNumber: string }>(null);
    const [pickUpLocation, setPickUpLocation] = useState<Address>(null);
    const [dropOffLocation, setDropOffLocation] = useState<Address>(null);
    const [pickUpDialogOpen, setPickUpDialogOpen] = useState(false);
    const [dropOffDialogOpen, setDropOffDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [riderID, setRiderID] = useState(0);
    const [reservationDetails, setReservationDetails] = useState<ReservationDetails>(null);

    const [userSession, loadingSession, error] = useContext(UserContext);

    const { showSnackbar } = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (userSession) {
            const { first_name, last_name, user: { email, phone_number, profile_picture } } = userSession?.rider ?? userSession?.driver;
            setUser({
                email: email,
                displayName: `${first_name} ${last_name}`,
                photoURL: profile_picture,
                phoneNumber: phone_number,
            });
            setRiderID(userSession?.rider?.id);
        }
    }, [userSession]);

    useEffect(() => {
        setStartAndEndDates(calculateCalendarDates());
    }, []);

    useEffect(() => {
        if (user && step === 0) {
            setStep(2);
        }
    }, [user, userSession]);

    const calculateCalendarDates = useCallback(() => {
        // Use the current date as the start date
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0); // set the time to the start of the day
    
        // Calculate the end date as 30 days ahead of the start date
        const endDate = addDays(startDate, 30);
        endDate.setHours(23, 59, 59, 999); // set the time to the end of the day
    
        return [format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')];
    }, []);

    const handleDateSelect = (date: Date) => {
        setSelectedDate(date);
    };

    const [startAndEndDates, setStartAndEndDates] = useState<string[]>(calculateCalendarDates());

    const [availableDates, loading, err] = useData({
        apiFunction: getAvailabilityBetweenDates,
        body: {
            startDate: startAndEndDates[0],
            endDate: startAndEndDates[1],
        },
        dependencies: [startAndEndDates, user?.photoURL],
    });
    if (err && !loading && step === 2) {
        showSnackbar('Error getting available dates.', 'error');
    }

    function handleLogin() {
        signInWithGoogle(({ user: { email, displayName, photoURL, phoneNumber } }) => {
            createRiderAccount({
                email: email,
                phone: phoneNumber,
                full_name: displayName,
                profile_picture: photoURL,
            }, id => {
                setRiderID(id)
                setStep(1);
                setTimeout(()=> window.location.reload(), 300)
            }, err => {
                console.log(err)
            });
        }, _ => {
            // setError(error);
        });

    }
    const validateInputs = () => {
        return pickUpLocation && dropOffLocation && selectedDate && user.phoneNumber;
    }
    const handleCloseSnackbar = (event) => {
        setSnackbarOpen(false);
    };

    function handleReserve() {
        const req: CreateReservationRequest = {
            email: user.email,
            end_address: dropOffLocation,
            full_name: user.displayName,
            phone: user.phoneNumber,
            start_address: pickUpLocation,
            start_time: toCSTISOString(selectedDate),
            rider_id: riderID
        }
        createRiderReservation(req,
            (res) => {
                showSnackbar('Reservation successful!', 'success');
                setReservationDetails(res);
                setStep(3)
            },
            (err) => {
                showSnackbar('Error creating reservation.', 'error');
            }
        );
    }

    if (userSession?.userType === 'driver') {
        // A notice saying that the user is a driver and cannot reserve a ride along with a log out button
        return (
            <Container maxWidth="lg" sx={{ mb: '30px' }}>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbarSeverity as AlertColor}
                        sx={{ width: '100%' }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Paper elevation={3} sx={{ p: 4, mt: 4, minHeight: '500px', opacity: loading && !error ? 0.5 : 1 }}>
                    <Stack direction="row" spacing={2} justifyContent="center" marginBottom={2}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Reserve a Ride
                        </Typography>
                        {loading && !error && <CircularProgress />}
                    </Stack>

                    <Stack display="flex" alignItems="center">
                        <Typography variant="h6" align="center" gutterBottom>
                            You are a driver and cannot reserve a ride.
                        </Typography>
                        <Button variant="contained" sx={{ backgroundColor: 'black', maxWidth: '300px' }}
                            fullWidth
                            onClick={() => {
                                logout(()=>{
                                    window.location.reload();
                                }, err => console.log(err));
                            }}>
                            Log Out
                        </Button>
                    </Stack>
                </Paper>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mb: '30px' }}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity as AlertColor}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Paper elevation={3} sx={{ p: 4, mt: 4, minHeight: '500px', opacity: loading && !error ? 0.5 : 1 }}>
                <Stack direction="row" spacing={2} justifyContent="center" marginBottom={2}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Reserve a Ride
                    </Typography>
                    {loading && !error && <CircularProgress />}
                </Stack>

                {
                    (step === 0 || step === 1) && <Stack display="flex" alignItems="center">
                        <Typography variant="h6" align="center" gutterBottom>
                            Sign in with your Google account to reserve a ride.
                        </Typography>
                        {step === 0 && <button type="button" className="google-sign-in-button" onClick={handleLogin}>
                            Sign in with Google
                        </button>}
                        {step === 1 && <UserProfileCard
                            name={user?.displayName}
                            email={user?.email}
                            phoneNumber={user?.phoneNumber || ''}
                            profilePicture={user?.photoURL} />
                        }
                        {step === 1 && <Button variant="contained" onClick={() => setStep(2)}>Continue</Button>}

                    </Stack>
                }
                {step === 2 && <>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} sm={4} md={5} lg={6}>
                            <Typography variant='h6' sx={{ mt: '10px', mb: '10px', fontWeight: 500 }}> Fill the
                                information below</Typography>
                            <UserProfileCard
                                name={user.displayName}
                                email={user.email}
                                phoneNumber={user.phoneNumber || ''}
                                onLogout={() => setStep(0)}
                                profilePicture={user.photoURL} />
                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    '& .MuiTextField-root': { mb: 2, backgroundColor: 'white' },
                                    width: '100%',
                                    maxWidth: '400px'
                                }}
                                noValidate
                                autoComplete="off"
                            >

                                <TextField
                                    label={t("Full Name")} defaultValue={user?.displayName} value={user?.displayName}
                                    onChange={(e) => setUser(
                                        { ...user, displayName: e.target.value })
                                    } variant="filled" size="small" />
                                <TextField
                                    label={t("Email")} defaultValue={user?.email} variant="filled" size="small"
                                    value={user?.email} onChange={(e) => setUser(
                                        { ...user, email: e.target.value })
                                    } />
                                <TextField
                                    label={t("Phone Number")} variant="filled" size="small" value={user?.phoneNumber}
                                    onChange={(e) => setUser(
                                        { ...user, phoneNumber: e.target.value })
                                    } />
                                {!pickUpLocation &&
                                    <Button variant="contained" sx={{ mb: 2 }} onClick={() => setPickUpDialogOpen(true)}>Choose
                                        a Pick Up Location</Button>}
                                {!pickUpLocation ?
                                    <MapPickerDialog
                                        title="Choose a pickup location"
                                        open={pickUpDialogOpen}
                                        onClose={(pickupAddress) => {
                                            setPickUpLocation(pickupAddress)
                                            setPickUpDialogOpen(false)
                                        }} /> : <AddressCard title="Pickup Address" address={pickUpLocation} />}
                                {!dropOffLocation &&
                                    <Button variant="contained" onClick={() => setDropOffDialogOpen(true)}>Choose a Drop
                                        off Location</Button>}
                                {!dropOffLocation ?
                                    <MapPickerDialog
                                        title="Choose a dropoff location"
                                        open={dropOffDialogOpen}
                                        onClose={(dropoffLocation) => {
                                            setDropOffLocation(dropoffLocation)
                                            setDropOffDialogOpen(false)
                                        }} /> : <AddressCard title="Dropoff Address" address={dropOffLocation} />}
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4} md={5}
                            lg={6}>

                            <Calendar
                                onDateSelect={handleDateSelect}
                                onNextMonth={(date: Date)=>setStartAndEndDates(calculateCalendarDates())}
                                onPrevMonth={(date: Date)=>setStartAndEndDates(calculateCalendarDates())}
                                availableDates={availableDates}
                                loading={loading}
                                error={err?.message}
                                minDate={(() => {
                                    const today = new Date();
                                    const yesterday = new Date(today);

                                    yesterday.setDate(yesterday.getDate() - 1);
                                    return yesterday;
                                })()}
                            />
                        </Grid>

                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button variant="contained" sx={{ backgroundColor: 'black', maxWidth: '300px' }}
                            disabled={!validateInputs()}
                            fullWidth
                            onClick={handleReserve}>
                            Reserve Ride
                        </Button>
                    </Box>
                </>}
                {step == 3 && reservationDetails && <ConfirmationCard reservation={reservationDetails} />}
            </Paper>
        </Container>
    );
};

export default ReserveRidePage;
