import React, { createContext, useState } from 'react';

export type OrientationType = 'vertical' | 'horizontal';

export interface LayoutInfo {
    orientation: OrientationType;
}

export const LayoutContext = createContext<
    [LayoutInfo, (info: LayoutInfo) => void]
>([{ orientation: 'horizontal' }, () => {}]);

export const LayoutProvider = ({ children }: { children: JSX.Element }) => {
    const [layoutInfo, setLayoutInfo] = useState<LayoutInfo>({
        orientation: 'horizontal'
    });

    return (
        <LayoutContext.Provider value={[layoutInfo, setLayoutInfo]}>
            {children}
        </LayoutContext.Provider>
    );
};
