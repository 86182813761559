import React, {useContext} from 'react';
import {AppBar, Toolbar,  Box, Stack} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import Logo from '../../assets/kassa-ride-logo.png';
import UserContext from "../../providers/userProvider";
import Avatar from "@mui/material/Avatar";
import Typography from "../../components/Typography";
import Button from "../../components/Button";

const Header: React.FC = () => {
    const navigate = useNavigate();
    const [user, loading, err] = useContext(UserContext);

    const handleNavigateContact = () => {
        navigate('/contact-us');
    };

    const handleNavigateHome = () => {
        navigate('/home');
    };

    const handleNavigateAbout = () => {
        navigate('/about-us');
    };

    const handleNavigateReserveRide = () => {
        navigate('/reserve-ride');
    };

    return (
        <AppBar position="static" sx={{m: 0, p: 0, backgroundColor: '#FFF'}}>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography
                        variant="h6"
                        sx={{flexGrow: 1, color: 'black', fontWeight: 'bold', cursor: 'pointer'}}
                        onClick={handleNavigateHome}
                    >
                        <img src={Logo} alt="Kassa Ride Logo" style={{width: '40px', marginTop: '5px'}}/>
                    </Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                  <Button color="inherit" sx={{ color: 'black' }} onClick={handleNavigateHome}>Home</Button>
                    <Button color="inherit" sx={{color: 'black'}} onClick={handleNavigateAbout}>About</Button>
                    <Button color="inherit" sx={{color: 'black'}} onClick={handleNavigateContact}>Contact</Button>
                    {(user?.rider?.user?.profile_picture || user?.driver?.user?.profile_picture) &&
                        <Avatar src={user?.rider?.user?.profile_picture || user?.driver?.user?.profile_picture}
                                sx={{width: 40, height: 40, marginX: '10px', display: {xs: 'none', sm:'unset'}}}/>
                    }

                    <Button variant="contained" sx={{backgroundColor: 'black', color: 'white', marginLeft: '10px'}}
                            onClick={handleNavigateReserveRide}>
                        Book Ride
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
