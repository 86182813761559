// translations.js
const translations = {
    amh: {
        "Travel Green. Travel in Style.": "አካባቢዎ ሳይበከል በምቾት ይጓዙ።",
        "Book Now": "ቦታ ያስይዙ",
        "Kassahun Zeleke": "ካሳሁን ዘለቀ",
        "KZ": "ካዘ",
        "Our Commitment to Safety and Transparency": "ለደህንነት እና ግልፅነት ያለን ቁርጠኝነት",
        "Transparency": "ግልፅነት",
        "Download PDF": "PDF አውርድ",
        "Kassahun has been a professional driver for over 10 years, known for his punctuality and friendly service. He loves exploring new routes and ensuring the safest and most comfortable journey for his passengers.": "አቶ ካሳሁን በሰዓቱ አክባሪነትና በወዳጅነት አገልግሎት የሚታወቁ ከ10 ዓመታት በላይ በሙያው ሹፌር ሆነው አገልግለዋል። አዳዲስ መንገዶችን ማሰስ እና ለተሳፋሪዎች በጣም አስተማማኝ እና ምቹ የሆነውን ጉዞ ማረጋገጥ ይወዳል።",
        "Who We Are": "ስለ እኛ",
        "Welcome to Kassa Ride, driven by Kassahun. Our aim? Reliable rides with a nod to the planet. By choosing Tesla, we're not just embracing efficiency; we're committing to a cleaner tomorrow. With Kassa Ride, every trip is a step toward a greener future.": "እንኳን ወደ ካሣ ራይድ በደኅና መጡ! ሾፌርዎ ካሣሁን እባላላሁ። ካሣ ራይድ ምቹ እና አስተማማኝ የሆነ አገልግሎታችንን በቴስላ መኪኖቻችን ስንሰጥዎ የተሻለ እና ያልተበከለ ከባቢ አየርን ለመጪው ትውልድ ለማስረከብ ድርሻችንን እንደምንወጣም በመተማመን ነው።።",
        "Eco-Friendly": "አረንጓዴ",
        "Ride with Kassa and green your journey; our sleek Tesla paves the way to a cleaner future, cutting carbon footprints one trip at a time.": "ካሣ ራይድን ሲጠቀሙ ዘመናዊ በሆኑ ቴስላ መኪኖቻችን ስለሚጓዙ ያልተበከለ ዓለም ለመጪው ትውልድ ለማስረከብ ድርሻዎትን ያበረክታሉ።",
        "Comfortable": "ምቹ",
        "Ride comfortably with Kassa, where roomy seats, great amenities, and the Tesla's quiet purr make for a relaxing trip.": "ምቹ በሆነው ካሣ ራይድ ይጠቀሙ! በቴስላ መኪኖቻችን ባልተጨናነቀ መቀመጫ ድምጻቸው በማይረብሽዎት የዘመኑ መኪኖች ጉዞዎን ምቹ እናደርግልዎታለን።",
        "Experienced Drivers": "ልምድ ያላቸው ሹፌሮች",
        "Travel securely with Kassa—our experienced hands and stellar safety record let you relax and enjoy the journey.": "ካሣ ራይድን ሲጠቀሙ ልምድ ባላቸው ሾፌሮቻችን፣ በተመሰከረት እና ከአደጋ ነጻ በሆነ የአግልግሎት ልምዳቸው ጉዞዎትን ያለ ሃሳብ  እንደሚያደርጉ ይተማመናሉ።",
        "Fair Pricing": "ርካሽ",
        "Expect transparency with Kassa—clear pricing, no hidden fees, no surge surprises. Your trust is our top priority.": "ካሣ ራይድን ሲጠቀሙ የተመጣጠነ እና ግልጽ ዋጋ እንሰጥዎታለን። ምንም ዓይነት ያላሳወቅንዎትን ክፍያ አናስከፍልዎትም። የእርስዎን አመኔታ ማግኘት ተቀዳሚ ተግባራችን ነው!",
        "Get In Touch": "እንገናኝ",
        "Have questions or simply want to chat about a ride? We're here to help. Your journey is important to us, and we value your trust and feedback.": "ጥያቄ ካለወት ወይም ስለ አገልግሎታችን ማንኛውንም ነገር ለማወቅ ከፈለጉ ያግኙን።",
        "Contact": "ያግኙን",
        "Contact Us": "ያግኙን",
        "Home": "ዋና ገጽ",
        "About": "ስለ እኛ",
        "About Us": "ስለ እኛ",
        "Book Ride": "ቦታ ያስይዙ",
        "Testimonials": "ምስክርነት",
        "Daniel Markos": "ዳንኤል ማርቆስ",
        "I've been using Kassa Ride for a few months now and it's been a game-changer for my daily commute. The app is easy to use and the drivers are always friendly and professional.": "ካሣ ራይድን መጠቀም ከጀመርኹ የተወሰኑ ወራት ሆኖኛል። ለዕለት ከዕለት ጉዞዬን በጣም የለወጠልኝ አገልግሎት ሆኖ አግኝቼዋለሁ። አፑ ለመጠቀም ቀላል ከመሆኑም በተጨማሪ ተግባቢ ሾፌሮችም ጉዞየን እንድወደው አድርገውኛል",
        "Phone: +1 (612) 275-3785": "",
        "Email: kzleke32@gmail.com": "",
        "Disclaimer: While we are proud to use a Tesla for its eco-friendly features, we recognize that no mode of transportation is entirely without an environmental footprint. We are committed to reducing our impact where possible, but our use of the term ’eco-friendly’ is in relation to traditional combustion engine vehicles.": "",
        "Copyright © 2023 Kassa Ride. መብቱ በህግ የተጠበቀ ነው.": "",

        "Kassa has been a professional driver for over 10 years, known for his punctuality and friendly service. He loves exploring new routes and ensuring the safest and most comfortable journey for his passengers.": "ካሳ በሰዓቱ እና በወዳጅነት አገልግሎት የሚታወቀው ከ10 አመት በላይ በሙያው ሹፌር ነው። አዳዲስ መንገዶችን ማሰስ እና ለተሳፋሪዎች በጣም አስተማማኝ እና ምቹ የሆነውን ጉዞ ማረጋገጥ ይወዳል።",
        "We are a company that specializes in providing personalized and reliable transportation services.": "ለግል የተበጀ እና አስተማማኝ የትራንስፖርት አገልግሎት በመስጠት ላይ የተካነ ኩባንያ ነን።",


        "Reserve a Ride": "ቀጠሮ ያስይዙ",
        "Sign in with your Google account to reserve a ride.": "",
        "Copyright © 2023 Kassa Ride. All Rights Reserved.": "",
        " Fill the information below": " 1. ከታች ያለውን መረጃ ይሙሉ",
        "Choose a Pick Up Location": "መነሻ ቦታ ይምረጡ",
        "Choose a Drop off Location": "የሚሄዱበትን ቦታ ይምረጡ",
        "Reserve Ride": "ቀጠሮ ያስይዙ",
        "Select Date and Time": "2. ቀን እና ሰዓት ይምረጡ",

        "Full Name": "ሙሉ ስም",
        "First Name": "የመጀመሪያ ስም",
        "Last Name": "የመጨረሻ ስም",
        "Email": "ኢሜይል",
        "Phone Number": "ስልክ ቁጥር",
        "Message": "መልዕክት",
        "Submit": "መልዕክት ይላኩልን",


    "Eyosias Samson": "ኢዮስያስ ሳምሶን",
    "Kassa Ride has made my life so much easier! I no longer have to worry about finding parking or dealing with traffic. The drivers are always on time and the cars are clean and comfortable.": "ካሳ ራይድ ህይወቴን በጣም ቀላል አድርጎልኛል! የመኪና ማቆሚያ ለማግኘት ወይም ከትራፊክ ጋር ስለመገናኘት ከእንግዲህ መጨነቅ የለብኝም። ሾፌሮቹ ሁል ጊዜ በሰዓቱ ናቸው እና መኪኖቹ ንጹህ እና ምቹ ናቸው።",

    },
    // ... other non-English languages
};

export default translations;

