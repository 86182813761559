// LanguageSelector.js
import React, {useEffect} from 'react';
import {useTranslation} from "../providers/intlProvider"; // Adjust the path as needed

const LanguageSelector = () => {
    const {setLanguage, currentLanguage} = useTranslation();

    const getStyle = (lang) => ({
        cursor: 'pointer',
        fontWeight: currentLanguage === lang ? 'bold' : 'normal',
        textDecoration: currentLanguage === lang ? 'underline' : 'none',
        marginRight: '5px',
        marginLeft: '5px'
    });

    return (
        <>
      <span style={getStyle('en')} onClick={() => setLanguage('en')}>English</span>
            <span>|</span>
            <span style={getStyle('amh')} onClick={() => setLanguage('amh')}>
        አማርኛ
      </span>
        </>
    );
};

export default LanguageSelector;
