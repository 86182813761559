import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from 'firebase/auth';


// Our web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB5RWWh4S-0JZQgHqB8bAU_CjBYUTxHYrM",
    authDomain: "kassa-ride.firebaseapp.com",
    projectId: "kassa-ride",
    storageBucket: "kassa-ride.appspot.com",
    messagingSenderId: "906441151328",
    appId: "1:906441151328:web:82873cd0b73bf9124dc405"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const googleProvider = new GoogleAuthProvider();

export const firebaseAuth =  getAuth(firebaseApp);