import * as React from 'react';
import { Container, Box, useTheme, useMediaQuery, Stack } from '@mui/material';
import homeImage from '../../assets/home-image.png';
import ecofriendly from '../../assets/ecofriendly.png';
import comfort from '../../assets/comfort.png';
import fairprice from '../../assets/fairprice.png';
import experienceddriver from '../../assets/experienceddriver.png';
import TestimonialComponent from './testimonial';
import { useNavigate } from 'react-router-dom'; 
import Logo from '../../assets/kassa-ride-logo.png';
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import LanguageSelector from "../../components/LanguageSelector";

const HomePage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate(); 
    const handleNavigateContact = () => {
        navigate('/contact-us'); 
      };

    const navigateReserveRide = useNavigate();
    const handleNavigateReserveRide = () => {
        navigateReserveRide('/reserve-ride');
    };



    return (
        <>
            {/* Main hero unit */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    maxWidth: '100%',
                    mx: 'auto',
                    px: isMobile ? 2 : 0,
                    mb: 0,
                }}
            >
                {/* Image container */}
                <Box
                    component="img"
                    sx={{
                        width: isMobile ? '100%' : '45%',
                        objectFit: 'cover',
                        mb: isMobile ? 2 : 0,
                    }}
                    src={homeImage}
                    alt="Green energy image"
                />
                {/* Content container */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        p: isMobile ? 2 : 19,
                    }}
                >
                    <img src={Logo} alt="Kassa Ride Logo" style={{ width: '80px', marginTop: '0px', marginLeft: '120px', marginBottom: '20px', display: 'block' }} />
                    <Typography
                        component="h1"
                        variant={isMobile ? 'h4' : 'h2'}
                        color="text.primary"
                        gutterBottom
                        sx={{
                            textAlign: isMobile ? 'center' : 'left',
                            width: '100%',
                        }}
                    >
                        
                        Travel Green. Travel in Style.
                    </Typography>
                    <Stack justifyContent={isMobile? 'center': 'left'} width='100%' flexDirection="row">
                        <LanguageSelector />
                    </Stack>

                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            width: isMobile ? '100%' : '30%',
                            backgroundColor: 'black',
                        }}
                        onClick={handleNavigateReserveRide}
                    >
                        Book Now
                    </Button>
                </Box>
            </Box>

            {/* Container for the "Who We Are" section and other content */}
            <Container sx={{ py: isMobile ? 4 : 8, mb: 10 }} maxWidth="md">
                {/* "Who We Are" Section */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ maxWidth: 600, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ fontSize: '37px' }} gutterBottom>
                            Who We Are
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.secondary">
                            Welcome to Kassa Ride, driven by Kassahun. Our aim? Reliable rides with a nod to the planet. By choosing Tesla, we&apos;re not just embracing efficiency; we&apos;re committing to a cleaner tomorrow. With Kassa Ride, every trip is a step toward a greener future.
                        </Typography>
                    </Box>
                </Box>
            </Container>

            {/* Container for the "Pictures and Descriptions" section */}
            <Container sx={{ mb: 10 }}>
                {/* Pictures and Descriptions Section */}
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', p: 4 }}>
                    <Box sx={{ flexGrow: 1, maxWidth: 300, textAlign: 'center', mb: isMobile ? 4 : 0, bgcolor: 'grey.200', p: 2, borderRadius: 5, m: 1, mr: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={ecofriendly} alt="placeholder" style={{ width: 80, height: 80, borderRadius: '50%' }} />
                            <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.primary" mt={2}>
                                Eco-Friendly
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: '14px' }} color="text.secondary" mt={2}>
                                Ride with Kassa and green your journey; our sleek Tesla paves the way to a cleaner future, cutting carbon footprints one trip at a time.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, maxWidth: 300, textAlign: 'center', mb: isMobile ? 4 : 0, bgcolor: 'grey.200', p: 2, borderRadius: 5, m: 1, mr: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={comfort} alt="placeholder" style={{ width: 80, height: 80, borderRadius: '50%' }} />
                            <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.primary" mt={2}>
                                Comfortable
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: '14px' }} color="text.secondary" mt={2}>
                                Ride comfortably with Kassa, where roomy seats, great amenities, and the Tesla&apos;s quiet purr make for a relaxing trip.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, maxWidth: 300, textAlign: 'center', mb: isMobile ? 4 : 0, bgcolor: 'grey.200', p: 2, borderRadius: 5, m: 1,  mr: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={experienceddriver} alt="placeholder" style={{ width: 80, height: 80, borderRadius: '50%' }} />
                            <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.primary" mt={2}>
                                Experienced Drivers
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: '14px' }} color="text.secondary" mt={2}>
                                Travel securely with Kassa—our experienced hands and stellar safety record let you relax and enjoy the journey.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, maxWidth: 300, textAlign: 'center', mb: isMobile ? 4 : 0, bgcolor: 'grey.200', p: 2, borderRadius: 5, m: 1, mr: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={fairprice} alt="placeholder" style={{ width: 80, height: 80, borderRadius: '50%' }} />
                            <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.primary" mt={2}>
                                Fair Pricing
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: '14px' }} color="text.secondary" mt={2}>
                                Expect transparency with Kassa—clear pricing, no hidden fees, no surge surprises. Your trust is our top priority.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>

            {/* "Testimonial" Section */}
            <TestimonialComponent />

            {/* Container for the "Get In Touch" section */}
            <Container sx={{ py: isMobile ? 4 : 8, mb: 20 }} maxWidth="md">
                {/* "Get In Touch" Section */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ maxWidth: 600, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ fontSize: '37px' }} gutterBottom>
                            Get In Touch
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: '17px' }} color="text.secondary">
                            Have questions or simply want to chat about a ride? We&apos;re here to help. Your journey is important to us, and we value your trust and feedback.
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                width: isMobile ? '100%' : '30%',
                                backgroundColor: 'black',
                            }}
                            onClick={handleNavigateContact}
                        >
                            Contact Us
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default HomePage;
