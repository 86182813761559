import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    format,
    isBefore,
    isAfter,
    addDays,
    startOfWeek,
    startOfMonth,
    endOfMonth,
    endOfWeek,
    isSameDay,
    addMonths,
    subMonths,
    parse,
    startOfDay
} from 'date-fns';
import './date-time-picker.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import Typography from "../../components/Typography";

interface CalendarProps {
    onDateSelect: (date: Date) => void;
    onNextMonth?: (date: Date) => void;
    onPrevMonth?: (date: Date) => void;
    availableDates: string[];
    minDate?: Date;
    maxDate?: Date;
    loading?: boolean;
    error?: string;
}

const TimePicker: React.FC<{
    onTimeSelect: (time: string) => void,
    selectedDate: Date,
    availableTimes: string[]
}> = ({onTimeSelect, selectedDate, availableTimes}) => {
    const [times, setTimes] = useState<string[]>([]);
    const [selectedTime, setSelectedTime] = useState<string | null>(null);

    useEffect(() => {
        const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0));
        const timeSlots = [];
        for (let minutes = 0; minutes < 24 * 60; minutes += 30) {
            const time = new Date(startOfDay.getTime() + minutes * 60000);
            const formattedTime = format(time, "yyyy-MM-dd'T'HH:mm:ss'Z'");
            const isEnabled = availableTimes?.includes(formattedTime);
            if (isEnabled) {
                timeSlots.push(format(time, 'hh:mm a'));
            }
        }
        setTimes(timeSlots);
    }, [selectedDate, availableTimes]);

    return (
        <div className="time-picker">
            {times.map(time => (
                <button
                    key={time}
                    onClick={() => {
                        if (time === selectedTime) {
                            setSelectedTime(null);
                            onTimeSelect(null);
                        } else {
                            setSelectedTime(time);
                            onTimeSelect(time);
                        }
                    }}
                    className={`time-slot ${time === selectedTime ? 'selected' : ''}`}
                >
                    {time}
                </button>
            ))}
        </div>
    );
};


// PropTypes for TimePicker
TimePicker.propTypes = {
    onTimeSelect: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    availableTimes: PropTypes.arrayOf(PropTypes.string).isRequired
};

const Calendar: React.FC<CalendarProps> = (
    {
        onDateSelect,
        onNextMonth,
        onPrevMonth,
        availableDates,
        minDate,
        maxDate,
        loading,
        error
    }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showTimePicker, setShowTimePicker] = useState(true);


    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon">
                        <FontAwesomeIcon onClick={prevMonth} icon={faChevronLeft} color='#617770'/>
                    </div>
                </div>
                <div className="col col-center">
                    <span>{format(currentMonth, dateFormat)}</span>
                </div>
                <div className="col col-end">
                    <div className="icon">
                        <FontAwesomeIcon onClick={nextMonth} icon={faChevronRight} color='#617770'/>
                    </div>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        const startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                
                // Updated logic to disable past dates
                const isDisabled = isBefore(cloneDay, startOfDay(new Date()));
    
                days.push(
                    <div
                        className={`col cell ${isDisabled ? "disabled" : isSameDay(day, selectedDate) ? "selected" : ""}`}
                        key={day.toString()}
                        onClick={() => !isDisabled && onDateClick(cloneDay)}
                    >
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day.toString()}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };

    const onDateClick = (day: Date) => {
        // If the clicked date is already selected, unselect it, otherwise select the new date
        if (selectedDate && isSameDay(day, selectedDate)) {
            setSelectedDate(null);
            onDateSelect(null);
            setShowTimePicker(false);
        } else {
            setSelectedDate(day);
            onDateSelect(day);
            setShowTimePicker(true);
        }
    };

    const renderTimePicker = () => {
        if (!selectedDate) return null;
        return <TimePicker onTimeSelect={onTimeSelect} selectedDate={selectedDate} availableTimes={availableDates}/>;
    };

    const onTimeSelect = (timeString) => {
        if (selectedDate) {
            // Check if timeString is not null and is a valid time string
            if (timeString && /^(\d{1,2}:\d{2} [AP]M)$/.test(timeString)) {
                // Convert 12-hour format time to a Date object
                const time = parse(timeString, 'hh:mm aa', new Date());
                const formattedDate = format(selectedDate, 'yyyy-MM-dd');
                const formattedTime = format(time, 'HH:mm:ss');
                const selectedDateTime = `${formattedDate}T${formattedTime}Z`;
                onDateSelect(parse(selectedDateTime, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date()));

            } else {
                // Handle the case when timeString is null or invalid
                console.log('Time unselected');
            }
        }
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
        onNextMonth && onNextMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
        onPrevMonth && onPrevMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="calendar-time-container">
            {error && <div className="error">{error}</div>}
            <div className="calendar-container">
                <Typography className="calendar-label" variant="title-md">Select Date and Time</Typography>
                <div className="calendar">
                    {renderHeader()}
                    {renderDays()}
                    {renderCells()}
                </div>

            </div>
            {showTimePicker && renderTimePicker()}
        </div>
    );
};

export default Calendar;
