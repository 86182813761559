import React, {useState} from 'react';
import {Box, Container, TextField, Grid, useMediaQuery, useTheme} from '@mui/material';
import ContactPhone from '../../assets/contactphone.png';
import {sendContactRequest} from "../../api/api";
import {useData} from "../../hooks/useData";
import {ContactRequest} from "../../api/apiTypes";
import {CircularProgress} from "@mui/material";

import Typography from "../../components/Typography";
import Button from "../../components/Button";
import {useTranslation} from "../../providers/intlProvider";

const emptyRequest: ContactRequest = {
    first_name: '',
    last_name: '',
    email: '',
    message: '',
    phone_number: ''
};

const ContactUsPage = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [validationErr, setValidationErr] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [Request, setRequest] = useState<ContactRequest>(emptyRequest);

    const [res, sending, err, send] = useData({
        apiFunction: sendContactRequest,
        body: Request,
        fetchOnMount: false,
        dependencies: [Request],
    });

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    // Update state variables when input fields change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };

    // Handle form submission
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission behavior

        if (firstName && lastName && email && phoneNumber && message) {
            setRequest({
                first_name: firstName,
                last_name: lastName,
                email: email,
                message: message,
                phone_number: phoneNumber
            });
            setValidationErr(''); // Clear any existing error
            setSubmitted(true); // Set submitted to true
            if (send) send();
        } else {
            setValidationErr('Please fill out all fields.'); // Set error message
            setSubmitted(false);
        }
    };
    return (
        <Container maxWidth="lg" sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: isMobile ? '140vh' : '100vh'
        }}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        src={ContactPhone} // Replace with your actual image path
                        sx={{width: '100%', maxWidth: 360, height: 'auto'}}
                        alt="Contact Us Illustration"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        Contact Us
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit} // Attach handleSubmit to form submission
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& .MuiTextField-root': {mb: 2, backgroundColor: 'white'},
                            width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label={t("First Name")}
                            variant="filled"
                            // size="small"
                            sx={{width: '100%'}}

                            name="firstName"
                            value={firstName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label={t("Last Name")}
                            variant="filled"
                            // size="small"
                            sx={{width: '100%'}}

                            name="lastName"
                            value={lastName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label={t("Email")}
                            variant="filled"
                            // size="small"
                            sx={{width: '100%'}}

                            name="email"
                            value={email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label={t("Phone Number")}
                            variant="filled"
                            // size="small"
                            sx={{width: '100%'}}

                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label={t("Message")}
                            variant="filled"
                            multiline
                            rows={4}
                            sx={{width: '100%'}}
                            // size="small"
                            name="message"
                            value={message}
                            onChange={handleInputChange}
                        />
                        <Button
                            variant="contained"
                            sx={{mt: 2, backgroundColor: 'black'}}
                            onClick={handleSubmit} // Attach handleSubmit to button click
                            disabled={!(firstName && lastName && email && phoneNumber && message)}
                        >
                            Submit
                        </Button>


                        {sending && <CircularProgress/>}
                        {submitted && !sending && !err && (
                            <Typography color="success" sx={{mt: 2}}>
                                Your message has been sent!
                            </Typography>
                        )}
                        {validationErr && (
                            <Typography color="warning" sx={{mt: 2}}>
                                {validationErr}
                            </Typography>
                        )}
                        {err && (
                            <Typography color="warning" sx={{mt: 2}}>
                                {err?.message}
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactUsPage;
