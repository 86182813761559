import { AxiosResponse } from 'axios';
import {useEffect, useRef, useState} from 'react';
import { APIRoute, APIRouteFunction, APIRouteWithBody } from '../api/api';
import { Duration } from '../dateutils/dateUtils';
import { AppError } from '../errors/error';
import { cacheData, getCachedData } from '../local-storage/indexedDB';

/**
 * `useData` is a custom hook for fetching and optionally transforming data.
 *
 * This hook does the following:
 * - Immediately fetches data from the provided API function on component mount.
 * - Manages local states for data, loading status, and errors.
 * - Optionally transforms the fetched data before setting it to state.
 * - Optionally caches the fetched or transformed data to localStorage.
 * - Re-fetches data when any specified dependency changes.
 *
 * Usage:
 * const [data, loading, error] = useData({
 *   apiFunction,
 *   body,
 *   shouldCache,
 *   cacheKey,
 *   dependencies: [dep1, dep2],
 *   transformData: (data) => {...} l
 * });
 *
 * Parameters:
 * - apiFunction: Function that fetches the data.
 * - body (optional): Request body for the API function.
 * - shouldCache (optional, default=false): Boolean indicating if data should be cached.
 * - cacheKey (optional): Key for caching if shouldCache is true.
 * - dependencies (optional): List of dependencies that trigger re-fetching when they change.
 * - transformData (optional): Function to transform the fetched data before setting it to state.
 *
 * Returns:
 * - data: The fetched and possibly transformed data or null.
 * - loading: Boolean indicating the loading state.
 * - error: Error state or null.
 */
type UseDataOptions<Request, Response, Transformed> = {
    apiFunction: APIRouteFunction<Request, Response>;
    body?: Request;
    shouldCache?: boolean;
    cacheKey?: string;
    dependencies?: any[];
    fetchOnMount?: boolean;
    expireInMs?: number;
    transformData?: (data: Response) => Transformed | null;
    cancelIfFalsy?: ()=>boolean;
};

export const useData = <
    Response = unknown,
    Request = unknown,
    Transformed = Response
>({
      apiFunction,
      body,
      shouldCache = false,
      cacheKey,
      dependencies = [],
      fetchOnMount = true,
      expireInMs = Duration.hours(1),
      cancelIfFalsy,
      transformData = (data: any) => data as Transformed
  }: UseDataOptions<Request, Response, Transformed>): [
        Transformed | null,
    boolean,
    AppError,
    () => Promise<void>
] => {
    const [data, setData] = useState<Transformed | null>(null);
    const [loading, setLoading] = useState<boolean>(fetchOnMount);
    const [error, setError] = useState<AppError | null>(null);
    const hasMounted = useRef(false);
    const handleResponse = async (
        responseData: Response | AxiosResponse<Response>
    ) => {

        const finalData = transformData(responseData as Response);
        if (shouldCache && cacheKey) {

            try {
                await cacheData(cacheKey, finalData, expireInMs);
            } catch (e) {
                console.error('Error saving to cache:', e);
            }
        }
        setData(finalData);
        setLoading(false);
    };
    const handleError = (err: AppError) => {

        console.error('Error fetching data:', err);
        setError(err);
        setLoading(false);
    };

    const fetchData = async () => {
        let cached: Transformed | null = null;
        if (shouldCache && cacheKey) {
            cached = await getCachedData(cacheKey);
        }

        if (cached) {
            setData(cached);
            setLoading(false);
        } else {
            setLoading(true);
            try {
                if (cancelIfFalsy && !cancelIfFalsy()) {
                    return;
                }
                if (isAPIRouteWithBody(apiFunction)) {
                    apiFunction(
                        body as Request,
                        handleResponse,
                        handleError
                    );
                } else {
                    (apiFunction as APIRoute<Response>)(
                        handleResponse,
                        handleError
                    );
                }
            } catch (err) {
                handleError(err);
            }
        }
    };
    useEffect(() => {
        if (fetchOnMount && hasMounted.current) {
            fetchData();
        }
        hasMounted.current = true;

    }, [
        // apiFunction,
        // body,
        shouldCache,
        cacheKey,
        fetchOnMount,
        expireInMs,
        ...dependencies
    ]);

    return [data, loading, error, fetchData];
};

// Type guard to check if the function is of type APIRouteWithBody
function isAPIRouteWithBody<Request, Response>(
    func: APIRouteFunction<Request, Response>
): func is APIRouteWithBody<Request, Response> {
    return (func as any).length === 3; // assuming APIRouteWithBody has 3 parameters
}
