import axiosLib from 'axios';
import 'firebase/auth';
import { getLoggedInUserAsync } from '../auth/auth';
import { BackendURL } from '../config/config';
import { AppError } from '../errors/error';

export const createAxiosInstance = (baseURL: string) => {
    return axiosLib.create({
        baseURL
    });
};

export const axios = createAxiosInstance(BackendURL);
export const geoAxios = createAxiosInstance(
    'https://maps.googleapis.com/maps/api/geocode'
);

// Add a request interceptor - this is used to add the Authorization header to requests
axios.interceptors.request.use(async (config) => {
    try {
        const user = await getLoggedInUserAsync();

        if (user) {
            const idToken = await user.getIdToken();
            config.headers.Authorization = `Bearer ${idToken}`;
        }

        return config;
    } catch (error) {
        // Handle errors if needed
        console.error('Error setting Authorization header:', error);
        return config;
    }
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.data) {
            // Wrap backend errors into AppError
            throw new AppError(
                error.response.data.message || 'An error occurred',
                error.response.status,
                error.response.data.errorCode
            );
        }

        // Wrap generic errors into AppError with a default status code, say 500
        throw new AppError(error.message || 'An error occurred', 500);
    }
);
