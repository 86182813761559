

export interface User {
    firebase_id: string;
    email: string;
    phone_number: string;
    user_type: UserType;
    profile_picture: string;
    address: {
        street: string;
        street2: string;
        city: string;
        state: string;
        zip_code: string;
    };
}

export enum UserType {
    Driver = 'driver',
    Rider = 'rider',
}


export interface Worker {
    id: number; // From gorm.Model
    user: User;
    firstName: string;
    lastName: string;
    DOB: Date;
    resumeURL: string;
    bio: string;
    age: number; // uint in Go can be represented as number in TypeScript
    rating: number; // float32 in Go can be represented as number in TypeScript
    workType: string; // Assuming WorkType can be represented as string, modify as per actual definition
    minPay: number; // float32 in Go can be represented as number in TypeScript
    SSN: string;
    industryId: number;
    industry: Industry;
}

export interface Industry {
    industryID: number;
    industryName: string;
    description: string;
}

export interface CurrentUser {
    userType: UserType;
    rider?: Rider;
    driver?: Driver;
}

export interface CreateUserRequest {
    firebaseID: string;
}

export interface SignInRequest {
    firebaseID: string;
}

export type DistanceUnit = 'mi' | 'km' | 'ft' | 'm';

export interface GeoLocation {
    latitude: number;
    longitude: number;
}

export interface Address {
    street: string;
    street2: string;
    city: string;
    state: string;
    zipCode: string;
}

export type PayType = 'hourly' | 'weekly' | 'monthly' | 'yearly';
export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC'
}

export interface BusinessJobListing {
    businessID: number;
    listingTitle: string;
    listingDescription?: string | null;
    payType: PayType;
    payAmount: number;
    deadlineDate: Date;
    location: GeoLocation;
    address: Address;
}

export interface JobListingFilters {
    listingTitle?: string;
    payAmount?: number;
    payType?: PayType;
    location?: GeoLocation;
    distance?: number;
    unit?: DistanceUnit;
}

export interface WorkerShiftFilters {
    start_date?: string;
    end_date?: string;
    pay_amount?: number;
    pay_type?: PayType;
    sort_field?: string;
    sort_order?: SortOrder;
    limit?: number;
    offset?: number;
}

export interface Shift {
    id?: number;
    created_at?: string;
    start_time?: string;
    end_time?: string;
    payment_rate?: PaymentRate;

    workers?: Worker[];
}

export interface PaymentRate {
    pay_amount: number;
    pay_type?: PayType;
}

export type ReservationStatus = "REQUESTED" | "APPROVED" | "CANCELLED" | "DELIVERED";


export interface Reservation {
    rider_id?: number;
    rider?: Rider;
    driver_id?: number;
    driver?: Driver;
    start_time?: string;
    end_time?: string;
    start_address?: Address;
    end_address?: Address;
    start_geolocation?: GeoLocation;
    end_geolocation?: GeoLocation;
    status?: ReservationStatus;
    payment_rate?: PaymentRate;
}

export interface CreateReservationRequest {
    rider_id?: number;
    full_name: string;
    email: string;
    phone: string;
    start_time?: Date | string;
    end_time?: Date | string;
    start_address?: Address;
    end_address?: Address;
    start_geolocation?: GeoLocation;
    end_geolocation?: GeoLocation;
    status?: ReservationStatus;
    payment_rate?: PaymentRate;
}

export interface Rider {
    id: number;
    user_id: number;
    user: User;
    first_name: string;
    last_name: string;
    reservations: Reservation[];
}

export interface Driver {
    user_id: number;
    user: User;
    first_name: string;
    last_name: string;
    num_rides: number;
    reservations: Reservation[];
    driver_availability: DriverAvailability[];
}

export interface DriverAvailability {
    driver_id: number;
    start_time: Date;
    end_time: Date;
    day_of_week: number;
    is_recurring: boolean;
}

export interface ContactRequest {
    first_name: string;
    last_name: string;
    email: string;
    message: string;
    phone_number: string;
}

export interface ReservationRequest {
    startDate?: string;
    endDate?: string;
}

export interface CreateRiderRequest {
    full_name: string;
    email: string;
    phone: string;
    profile_picture: string;
}

export interface AvailabilityRequest {
    startDate?: string;
    endDate?: string;
}

export interface CreateRiderRequest {
    full_name: string;
    email: string;
    phone: string;
    profile_picture: string;
}

export interface AvailabilityRequest {
    startDate?: string;
    endDate?: string;
}
export interface CreateRiderRequest {
    full_name: string;
    email: string;
    phone: string;
    profile_picture: string;
}

export type ReservationDetails = Reservation & {
    CreatedAt: string;
    price: number;
    distance_in_miles: number;
    duration_in_minutes: number;
}