import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import './admin.css';
import {useData} from "../../hooks/useData";
import {getReservationDetailsByID, updateReservationStatus} from "../../api/api";
import {Box, Button, CircularProgress, Container, Stack} from "@mui/material";
import {ReservationCard} from "./reservation-detail-card";
import Avatar from "@mui/material/Avatar";
import {EmailOutlined, EmailRounded, PhoneRounded} from "@mui/icons-material";
import {ReservationDetails, ReservationStatus} from '../../api/apiTypes';
import {useSnackbar} from "../../hooks/useSnackbar";
import { useTheme } from '@mui/material/styles';

const RideDetails: React.FC = () => {
    const theme = useTheme();
    const {id} = useParams<{ id: string }>();
    const [details, loading, err, fetchData] = useData({
        apiFunction: getReservationDetailsByID,
        body: {
            id: +id
        }
    })

    useEffect(() => {
        id && fetchData();
    }, []);

    const {showSnackbar} = useSnackbar();

    const formatDateTime = (date: string) => new Date(date).toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'America/Chicago'
    });

    const {rider} = details || {};

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="80vh"
            >
                <CircularProgress/>
            </Box>
        );
    }

    if (!details && !loading) {
        return <Box sx={{ p: 3, textAlign: 'center' }}>No ride details found.</Box>;
    }

    const changeReservationStatus = (status: ReservationStatus) => () => {
        updateReservationStatus({id: +id, status}, () => {
            showSnackbar('Reservation status updated successfully.', 'success');
            setTimeout(() => window.location.reload(), 1000);
        }, (err) => {
            showSnackbar(`An error has occurred. Please reach out to admins with the error: ${err}`, 'success');
        });
    };

    function getAppropriateButtons(details: ReservationDetails): React.ReactNode[] {
        return {
            'REQUESTED': [
                <Button variant="contained" color="success"
                        onClick={changeReservationStatus("APPROVED")}>Accept</Button>,
                <Button variant="outlined" color="error"
                        onClick={changeReservationStatus("CANCELLED")}>Decline</Button>,
            ],
            'APPROVED': [
                <Button variant="contained" color="success" onClick={changeReservationStatus("DELIVERED")}>Set as
                    Delivered</Button>,
                <Button variant="outlined" color="error" onClick={changeReservationStatus("CANCELLED")}>Cancel</Button>,
            ],
            'CANCELLED': [
                <Button variant="contained" color="success"
                        onClick={changeReservationStatus("APPROVED")}>Approve</Button>,
            ],
            'DELIVERED': []
        }[details.status] || [];
    }

    return (
        <Container>
            {details && <ReservationCard reservation={details}/>}
            <div className="ride-details-container">
                <div className="ride-details-card">
                    <Stack direction="row" spacing={3} className="ride-details-profile">
                        {!details?.rider?.user?.profile_picture ? <div className="profile-image-placeholder">
                            {/* Assuming the user's initials are used when there's no profile image */}
                            <span>{`${rider.first_name} ${rider.last_name}`.split(' ').map(n => n[0]).join('')}</span>
                        </div> : <Avatar alt="Rider Profile Picture" src={details?.rider?.user?.profile_picture}/>
                        }
                        <div className="profile-name-status">
                            <h1>{`${rider.first_name} ${rider.last_name}`}</h1>
                            <span
                                className="status">Appointment created {formatDateTime(details?.CreatedAt)}</span> {/* Indicative status */}
                        </div>

                    </Stack>
                    <div className="ride-details-body">
                        <div className="ride-detail">
                            <EmailRounded sx={{mr: 2}}/>
                            <a href={`mailto:${rider?.user?.email}`} target="_blank"
                               rel="noopener noreferrer">{rider?.user?.email}</a>
                        </div>
                        <div className="ride-detail">
                            <PhoneRounded sx={{mr: 2}}/>
                            <a href={`tel:${rider?.user?.phone_number}`} target="_blank"
                               rel="noopener noreferrer">{rider?.user?.phone_number}</a>
                        </div>
                    </div>
                    <Stack className="ride-details-footer" spacing={2}>
                        {getAppropriateButtons(details)}
                    </Stack>
                </div>
            </div>
        </Container>
    );
};

export default RideDetails;