/**
 * indexedDB.ts
 *
 * Utility functions for working with the IndexedDB browser storage API.
 *
 * This module provides an asynchronous interface to cache and retrieve data from IndexedDB.
 * It includes:
 * 1. A method to open (or upgrade) a specific IndexedDB database.
 * 2. A method to cache data with a time-to-live (TTL) value.
 * 3. A method to fetch cached data, checking its validity based on the TTL.
 *
 * The caching mechanism allows data to be stored temporarily, improving performance by
 * reducing the need for frequent network requests or heavy computations. However, stale data
 * is automatically invalidated after its TTL expires.
 *
 * Note: IndexedDB is a low-level API for large amounts of structured data, allowing for high
 * performance operations with automatic indexing. It's ideal for large datasets and situations
 * where data needs to be accessed offline.
 */

export type CachedItem<Response = unknown> = {
    key: string;
    data: Response;
    expiration: number;
};

export const openDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('MyDatabase', 1);

        request.onupgradeneeded = function (event: IDBVersionChangeEvent) {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains('cache')) {
                db.createObjectStore('cache', { keyPath: 'key' });
            }
        };

        request.onsuccess = function (event: Event) {
            const db = (event.target as IDBOpenDBRequest).result;
            resolve(db);
        };

        request.onerror = function (event: Event) {
            reject('Error opening IndexedDB');
        };
    });
};

export const cacheData = async <Response = unknown>(
    key: string,
    data: Response,
    ttl = 3600000 // default TTL: 1 hour
): Promise<void> => {
    const db = await openDB();
    const tx = db.transaction('cache', 'readwrite');
    const store = tx.objectStore('cache');
    const item: CachedItem<Response> = {
        key,
        data,
        expiration: Date.now() + ttl
    };
    store.add(item);
    return new Promise((resolve, reject) => {
        tx.oncomplete = () => resolve();
        tx.onerror = () => reject(tx.error);
    });
};

export const getCachedData = async <Response = unknown>(
    key: string
): Promise<Response | null> => {
    const db = await openDB();
    const tx = db.transaction('cache', 'readonly');
    const store = tx.objectStore('cache');
    const request = store.get(key);

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            const cachedItem = request.result as
                | CachedItem<Response>
                | undefined;

            if (cachedItem && cachedItem.expiration > Date.now()) {
                resolve(cachedItem.data);
            } else if (cachedItem) {
                store.delete(key); // Remove expired data
                resolve(null);
            } else {
                resolve(null);
            }
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
};
