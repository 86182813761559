import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../../assets/kassa-ride-white.png';
import { Link } from 'react-router-dom';

import Typography from "../../components/Typography";

type FooterProps = {
  // define any props here, for example:
  // logo: string;
};

const Footer: React.FC<FooterProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component='footer'
      sx={{
        py: isMobile ? 2 : 3,
        px: isMobile ? 2 : 4,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: '0',
        padding: '0',
        maxWidth: isMobile ? '96%' : '98%',
      }}
    >
      <Grid container spacing={isMobile ? 2 : 3} justifyContent='space-between' alignItems='center' color={'white'} sx={{backgroundColor: 'black', margin: '0', paddingBottom: '30px'}}>
        <Grid item xs={12} sm={2}>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
          <img src = {Logo} style={{width: '100px', marginTop: '10px', marginLeft: '50px'}} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={10} container justifyContent={isMobile ? 'start' : 'end'} >
          <Link to='/logindriver' style={{ textDecoration: 'none', color: 'white', marginRight: '100px' }}>Login as Driver</Link>
          <Box display='flex' flexDirection='column' alignItems={isMobile ? 'flex-start' : 'flex-start'} sx={{width: '100%'}}>
            <Typography variant='body1'>
              Phone: +1 (651) 895-5436
            </Typography>
            <Typography variant='body1'>
              Email: kassaridemn@gmail.com
            </Typography>
            <Typography variant='body2' sx={{ mt: 2, maxWidth: '95%'}}>
              Disclaimer: While we are proud to use a Tesla for its eco-friendly features, we recognize that no mode of transportation is entirely without an environmental footprint. We are committed to reducing our impact where possible, but our use of the term &rsquo;eco-friendly&rsquo; is in relation to traditional combustion engine vehicles.
            </Typography>
            <Typography variant='caption' display='block' sx={{ mt: 2 }}>
              Copyright © 2023 Kassa Ride. All Rights Reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
