import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {Address} from "../../api/apiTypes";

interface AddressCardProps {
    address: Address;
    title: string;
}

const AddressCard: React.FC<AddressCardProps> = ({ title, address }) => {
    return (
        <Card sx={{ minWidth: 275, marginY: '10px', padding: '10px', boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h6" component="div" gutterBottom fontWeight="bold">
                    {title || 'Address Details'}
                </Typography>
                <Box display="flex" alignItems="center" marginTop={2}>
                    <LocationOnIcon color="primary" sx={{ marginRight: 1 }} />
                    <Typography variant="body1" fontWeight="medium">Street: {address.street}</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                    <CityIcon color="secondary" sx={{ marginRight: 1 }} />
                    <Typography variant="body1" fontWeight="medium">City: {address.city}</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                    <PublicIcon color="secondary"sx={{ marginRight: 1, color: 'action.active' }} />
                    <Typography variant="body1" fontWeight="medium">State: {address.state}</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                    <MailOutlineIcon color="secondary"sx={{ marginRight: 1, color: 'action.active' }} />
                    <Typography variant="body1" fontWeight="medium">Zip Code: {address.zipCode}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AddressCard;
