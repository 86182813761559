import React, {useEffect, useState} from 'react';
import '../common/styles.css';
import {Box, Button, Card, CardContent, FormControl, FormLabel, Input, TextField, Typography} from '@mui/joy';
import {Link, Navigate} from 'react-router-dom';
import {
    logInWithEmailAndPassword,
    logInWithEmailAndPasswordAsync,
    signInWithGoogle,
    userIsLoggedInAsync
} from '../../auth/auth';
import { redirect  } from 'react-router-dom';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {signIn} from "../../api/api";

const useStyles: any = () => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    card: {
        minWidth: 300,
        maxWidth: 800,
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "1rem",
    },
    textField: {
        width: '100%',
        marginBottom: "2rem",
    },
    submitButton: {
        width: '100%',
    },
    formControl: {
        width: '300px',
        marginBottom: "2rem",
    },
    bottomText: {
        marginTop: "1rem",
    }
});

export const LoginPage = () => {
    const styles = useStyles();
    const [error, setError] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    const redirectIfLoggedIn = async () => {
        try {
            const loggedIn = await userIsLoggedInAsync();
            if (loggedIn) {
                setLoggedIn(true);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onErr = (err: any) => {
        console.error('Error signing in:', err);
        setError('Login failed. Please check your credentials.');
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        try {
            const user = await logInWithEmailAndPasswordAsync(email, password);
            if (user){
                signIn( user => {
                    localStorage.setItem('user', JSON.stringify(user));
                    setLoggedIn(true);
                }, onErr);
            }
        } catch (err) {
            onErr(err);
        }
    };

    const signInWithGooglePopup =  () =>  {
        signInWithGoogle((user: any)=>{
            signIn( user => {
                localStorage.setItem('user', JSON.stringify(user));
                setLoggedIn(true);
            }, onErr);
        }, onErr);
    };

    useEffect(()=>{
        redirectIfLoggedIn();
    },[]);

    return (
        <div style={styles.root} className={'loginRoot'}>
            {loggedIn && <Navigate to={'/home'}/> }
            <Card style={styles.card}>
                <CardContent>
                    <Typography level="body-md" component="h2">
                        Sign In
                    </Typography>
                    <form style={styles.form} onSubmit={handleSubmit}>
                        <FormControl style={styles.formControl}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                name={'email'}
                                type={'email'}
                            />
                        </FormControl>
                        <FormControl style={styles.formControl}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                placeholder="8 characters minimum"
                                name={'password'}
                                type={'password'}
                            />
                        </FormControl>
                        {error && (
                            <p style={{ color: 'red' }}>{error}</p> // Display the error message if it's not empty
                        )}
                        <Button
                            variant="soft"
                            color="primary"
                            style={styles.submitButton}
                            type="submit"
                            // Add your submit logic here
                        >
                            Sign In
                        </Button>

                        <Typography
                            level="body-sm"
                            component="p"
                            style={{textAlign: 'center', margin: '16px 0', position: 'relative'}}
                        >
                            OR
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={styles.submitButton}
                            onClick={signInWithGooglePopup}
                        >
                            Continue with Google
                        </Button>
                        <Typography level="body-sm" component="p" style={styles.bottomText}>
                            Don&apos;t have an account yet? <Link to={'/signup'}>Sign Up</Link>
                        </Typography>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};