import React from 'react';
import {format, isSameDay, isAfter} from 'date-fns';
import './admin.css';
import { useMediaQuery, useTheme } from '@mui/material';

interface ReservedRidesComponentProps {
    reservedRides: Array<{
        id: number;
        name: string;
        datetime: string; // ISO date string
        status: string;
        payrate: number;
    }>;
}

const ReservedRidesComponent: React.FC<ReservedRidesComponentProps & { selectedDate: Date }> = (
    {
        reservedRides,
        selectedDate
    }) => {
    const today = new Date();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className="reserved-rides">
            <h2>Upcoming Rides</h2>
            {reservedRides.length > 0 && (
                <>
                    {!isMobile && <h3>{format(selectedDate, 'EEEE, MMMM d')}</h3>}
                    {reservedRides.map(ride => (
                        <a href={`/ride-details/${ride.id}`} className="ride-link" key={ride.id}>
                            <div className="ride">
                                <div className='rider-name'>{ride.name}</div>
                                <div className='rider-time'>{format(new Date(ride.datetime), "h:mm a")}</div>
                                <div className='rider-payrate'>${ride.payrate}</div>
                                <div className="statusInList">{ride.status}</div>  
                            </div>
                        </a>
                    ))}
                </>
            )}
        </div>
    );
};

export default ReservedRidesComponent;
