/**
 * This file contains all the API routes for the application.
 * In addition to this, it implements a wrapper for axios (the http client used in this project)
 * to make it easier to use the API routes.
 */

import { AxiosResponse } from 'axios';
import { AppError } from '../errors/error';
import {
    AvailabilityRequest,
    BusinessJobListing, ContactRequest, CreateReservationRequest, CreateRiderRequest,
    CreateUserRequest,
    CurrentUser,
    Industry,
    JobListingFilters, Reservation, ReservationDetails, ReservationRequest, ReservationStatus,
    Shift,
    User,
    WorkerShiftFilters
} from './apiTypes';
import { axios } from './axios';

// QueryParams is a type that represents the query parameters of a http request
// Example: /v1/skills?name=John&age=20
// -> name and age are the query parameters of the request
interface QueryParams {
    [key: string]: string | number;
}

// APIRoute is a type that represents a function that makes a http request to the backend *without* a body
export type APIRoute<Response = unknown> = (
    onLoad: (data: Response | AxiosResponse<Response>) => void,
    onErr?: (err: unknown) => void
) => void;

// APIRouteWithBody is a type that represents a function that makes a http request to the backend *with* a body
export type APIRouteWithBody<Request = unknown, Response = unknown> = (
    data: Request,
    onLoad: (data: Response | AxiosResponse<Response>) => void,
    onErr?: (err: unknown) => void
) => void;

// APIRouteFunction is a type that represents a function that makes a http request to the backend
// It can be either a APIRoute or a APIRouteWithBody
export type APIRouteFunction<Request = unknown, Response = unknown> =
    | APIRoute<Response>
    | APIRouteWithBody<Request, Response>;

// paramsToQueryString converts a QueryParams object to a query string
function paramsToQueryString(params: QueryParams) {
    const queryString = Object.keys(params)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');

    return queryString;
}

// GET is a wrapper for axios.get
export function GET<ResponseType = unknown>(
    url: string,
    params?: QueryParams
): Promise<ResponseType> {
    return axios
        .get<ResponseType>(
            params ? `${url}?${paramsToQueryString(params)}` : url
        )
        .then((res) => res.data);
}

// DELETE is a wrapper for axios.delete
function DELETE(url: string, params?: QueryParams): Promise<unknown> {
    return axios.delete(params ? `${url}?${paramsToQueryString(params)}` : url);
}

// POST is a wrapper for axios.post
export function POST<RequestType = unknown, ResponseType = unknown>(
    url: string,
    body?: RequestType,
    params?: QueryParams,
    headers: unknown = {}
): Promise<ResponseType> {
    return axios
        .post<ResponseType>(
            params ? `${url}?${paramsToQueryString(params)}` : url,
            body
        )
        .then((res) => res.data);
}

// PUT is a wrapper for axios.put
export function PUT<ResponseType = unknown>(
    url: string,
    body: unknown,
    params?: QueryParams,
    headers: unknown = {}
): Promise<AxiosResponse<ResponseType>> {
    return axios.put<ResponseType>(
        params ? `${url}?${paramsToQueryString(params)}` : url,
        body
    );
}

export function createUser(
    data: CreateUserRequest,
    onLoad: (data: unknown) => void,
    onErr?: (err: unknown) => void
) {
    const url = `/v1/user`;
    console.log('Sending POST to ', url);
    POST(url, data).then(onLoad).catch(onErr);
}

export const signIn = (
    onLoad: (value: User | AxiosResponse<User, any>) => void,
    onErr?: (err: unknown) => void
) => {
    const url = `/v1/user/firebaseSignIn`;
    console.log('Sending GET to ', url);
    GET<User>(url).then(onLoad).catch(onErr);
};

export const getCurrentUser = (
    onLoad: (value: CurrentUser) => void,
    onErr?: (err: unknown) => void
) => {
    const url = `/v1/user`;
    console.log('Sending GET to ', url);
    GET<CurrentUser>(url).then(onLoad).catch(onErr);
};

export const getLoggedInUser = (
    onLoad: (value: User | AxiosResponse<User, any>) => void,
    onErr?: (err: unknown) => void
) => {
    const url = `/v1/user/firebaseSignIn`;
    console.log('Sending GET to ', url);
    GET<User>(url).then(onLoad).catch(onErr);
};


export function getShiftsForWorker(
    data: WorkerShiftFilters,
    onLoad: (data: Shift[]) => void,
    onErr?: (err: unknown) => void
) {
    const url = `/v1/worker/shifts`;
    console.log('Sending GET to ', url);
    GET<Shift[]>(url, data as QueryParams)
        .then(onLoad)
        .catch(onErr);
}

export function sendContactRequest(
    data: ContactRequest,
    onLoad: (data: ContactRequest) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/driver/contact `;
    console.log('Sending POST to ', url);
    POST<ContactRequest>(url, data)
        .then(onLoad)
        .catch(onErr);
}

export function getReservationsForDriverBetweenDates(
    data: ReservationRequest,
    onLoad: (data: Reservation[]) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/driver/reservations`;
    console.log('Sending GET to ', url);
    GET<Reservation[]>(url, data as QueryParams)
        .then(onLoad)
        .catch(onErr);
}

export function createRiderAccount(
    data: CreateRiderRequest,
    onLoad: (data: number) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/rider`;
    console.log('Sending POST to ', url);
    POST<CreateRiderRequest, number>(url, data)
        .then(onLoad)
        .catch(onErr);
}

export function createRiderReservation(
    data: CreateReservationRequest,
    onLoad: (data: ReservationDetails) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/reservations/create`;
    console.log('Sending POST to ', url);
    POST<CreateReservationRequest, Reservation>(url, data)
        .then(onLoad)
        .catch(onErr);
}

export function getAvailabilityBetweenDates(
    data: AvailabilityRequest,
    onLoad: (data: string[]) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/rider/availability`;
    console.log('Sending GET to ', url);
    GET<string[]>(url, data as QueryParams)
        .then(onLoad)
        .catch(onErr);
}

export function getReservationDetailsByID(
    data: {id: number},
    onLoad: (data: ReservationDetails) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/reservations/details`;
    console.log('Sending GET to ', url);
    GET<ReservationDetails>(url, data as QueryParams)
        .then(onLoad)
        .catch(onErr);
}

export function updateReservationStatus(
    data: {id: number, status: ReservationStatus},
    onLoad: (data: unknown) => void,
    onErr?: (err: AppError) => void
) {
    const url = `/v1/reservations/update`;
    console.log('Sending PUT to ', url);
    PUT(url, {}, data as QueryParams)
        .then(onLoad)
        .catch(onErr);
}

// async is a wrapper for APIRouteFunction that returns a Promise, converting a synchronous function to an asynchronous one
export function async<Request = unknown, Response = unknown>(apiFunction: any) {
    return (body?: Request) => {
        return new Promise<Response>((resolve, reject) => {
            if (isAPIRouteWithBody(apiFunction)) {
                // @ts-ignore
                apiFunction(body!, resolve, reject);
            } else {
                apiFunction(resolve, reject);
            }
        });
    };
}

// Type guard to check if the function is of type APIRouteWithBody
function isAPIRouteWithBody<Request, Response>(
    func: APIRouteFunction<Request, Response>
): func is APIRouteWithBody<Request, Response> {
    return (func as any).length === 3; // assuming APIRouteWithBody has 3 parameters
}
