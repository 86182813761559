import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography, {TypographyProps} from '@mui/material/Typography';
import teslaPic from '../../assets/blue-tesla1.png';
import {signInWithGoogle} from "../../auth/auth";
import {useEffect, useState} from "react";
import {useUser} from "../../hooks/useUser";
import {useNavigate} from "react-router-dom";

interface CopyrightProps extends TypographyProps {
    children?: React.ReactNode;
}

function Copyright(props: CopyrightProps) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                KassaRide
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const allowedEmails = [
    'birukabraham741@gmail.com',
    'kassarideMN@gmail.com',
    'kassaridemn@gmail.com',
    'ananya.meseret@gmail.com',
    'mengi024@morris.umn.edu',
];

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignInSide() {
    const [error, setError] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [user, loading, userError] = useUser()
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
    };

    function handleLogin() {
        signInWithGoogle(({user: {email: e}}) => {
            setEmail(e);
        }, error => {
            setError(error);
        });
    }

    useEffect(() => {
        console.log({user, loading, email})
        if (user && !loading && user?.userType === 'driver') {
            navigate('/admin');
        }
    }, [email, loading]);

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <CssBaseline/>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${teslaPic})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2, backgroundColor: 'white', color: 'black'}}
                            onClick={handleLogin}
                        >
                            Sign In with Google
                        </Button>
                        <Typography variant="body2" color="error" align="center" sx={{mt: 1}}>
                            {error && error}
                        </Typography>
                        <Copyright sx={{mt: 5}}/>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}