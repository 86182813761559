import React, { useState, useCallback, useEffect } from 'react';
import {GoogleMap, Marker, LoadScript, Autocomplete, MarkerF} from '@react-google-maps/api';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import {Address} from "../../api/apiTypes";

const MapPickerDialog = ({ title, open, onClose }) => {
    const [autocomplete, setAutocomplete] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState<Address>({ street: '', street2: '', city: '', state: '', zipCode: '' });
    const [typedAddress, setTypedAddress] = useState(''); // State to hold the displayed address
    const [displayedAddress, setDisplayedAddress] = useState(''); // State to hold the displayed address
    const [location, setLocation] = useState({ lat: 44.986656, lng: -93.258133 });

    const onLoad = (autoC) => {
        setAutocomplete(autoC);
    };

    const parseAddressComponents = (place) => {
        let address = { street: '', street2: '', city: '', state: '', zipCode: '' };
        place.address_components.forEach(component => {
            const types = component?.types;
            if (types?.includes('street_number')) {
                address.street = component?.long_name;
            } else if (types?.includes('route')) {
                address.street += ` ${component?.long_name}`;
            } else if (types?.includes('sublocality_level_1')) {
                address.street2 = component?.long_name;
            } else if (types?.includes('locality')) {
                address.city = component?.long_name;
            } else if (types?.includes('administrative_area_level_1')) {
                address.state = component?.short_name;
            } else if (types?.includes('postal_code')) {
                address.zipCode = component?.long_name;
            }
        });
        return address;
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            const address = parseAddressComponents(place);
            setSelectedAddress(address);
            setDisplayedAddress(place.formatted_address); // Set the displayed address
            setTypedAddress(place.formatted_address); // Set the displayed address
            setLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        } else {
            console.error('Autocomplete is not loaded yet!');
        }
    };

    const mapStyles = {
        height: "400px",
        width: "100%"
    };

    // bounds for minnesota
    const bounds = {
        north: 49.5,
        south: 43.0,
        east: -89.00,
        west: 97.5,
    };

    return (
        <Dialog open={open} onClose={() => onClose(selectedAddress)} fullWidth maxWidth="md" sx={{zIndex: '20!important'}}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                    <Autocomplete bounds={bounds} onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <TextField
                            label="Address"
                            variant="outlined"
                            sx={{mt: 1}}
                            fullWidth
                            value={typedAddress}
                            onChange={(e) => {
                                setSelectedAddress({...selectedAddress, street: e.target.value});
                                setTypedAddress(e.target.value)
                            }}
                        />
                    </Autocomplete>
                    <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={15}
                        center={location}
                    >
                        <MarkerF position={location} />
                    </GoogleMap>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={!displayedAddress} onClick={() => onClose(selectedAddress)}>Select</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MapPickerDialog;